import feedsActionTypes from "./feedsActionTypes";

const getFeeds = payload => {
  return {
    type: feedsActionTypes.GET_FEEDS,
    payload
  };
};

const getVideos = payload => {
  return {
    type: feedsActionTypes.GET_VIDEOS,
    payload
  };
};

const getPlayerPositions = payload => {
  return {
    type: feedsActionTypes.GET_PLAYER_POSITIONS,
    payload
  };
};

const replacePlayer = payload => {
  return {
    type: feedsActionTypes.REPLACE_PLAYER,
    payload
  };
};

const recreateVideo = payload => {
  return {
    type: feedsActionTypes.RECREATE_VIDEO,
    payload
  };
};

export {
  getFeeds,
  getVideos,
  getPlayerPositions,
  replacePlayer,
  recreateVideo
};
