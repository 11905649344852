import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { replacePlayer, recreateVideo } from "features/feeds/feedsActions";
import Spinner from 'shared/components/Spinner';
import { getHeaders } from "shared/apiHelper";

import ReplayPage from "./ReplayPage";

const mapStateToProps = state => ({
  replacePlayerList: state.feedsReducer.replacePlayerList,
  token: state.authReducer.token
});

const mapDispatchToProps = dispatch => ({
  replacePlayer: params => dispatch(replacePlayer(params)),
  recreateVideo: params => dispatch(recreateVideo(params))
});

class ReplayPageContainer extends React.PureComponent {
  static propTypes = {
    replacePlayer: PropTypes.func.isRequired,
    recreateVideo: PropTypes.func.isRequired,
    replacePlayerList: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    token: PropTypes.object.isRequired
  };

  componentDidMount() {
    if (this.props.location.state.selectedPlayer) {
      this.replacePlayer();
    }
  }

  replacePlayer = () => {
    const { token, replacePlayer, location } = this.props;
    const headers = getHeaders(token);

    replacePlayer({
      headers,
      params: {
        id: location.state.selectedPlayer.id,
      }
    });
  };

  recreateVideo = data => {
    const { token, recreateVideo } = this.props;
    const headers = getHeaders(token);

    recreateVideo({
      headers,
      ...data
    });
  };

  render() {
    if (!this.props.location.state.selectedPlayer) {
      this.props.history.push("/app/feeds");
    } else {
      if (this.props.replacePlayerList.isFulfilled) {
        return (
          <ReplayPage
            recreateVideo={this.recreateVideo}
            replacePlayerList={this.props.replacePlayerList.data}
            feed={this.props.location.state.feed}
            history={this.props.history}
          />
        );
      }
      return <Spinner />;
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReplayPageContainer)
);
