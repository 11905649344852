import contestsActionTypes from "./contestsActionTypes";

const initialState = {
  contests: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  contest: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  joinContestData: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  contestResult: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  }
};

const contestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case contestsActionTypes.GET_CONTESTS_PENDING:
      return Object.assign({}, state, {
        contests: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case contestsActionTypes.GET_CONTESTS_FULFILLED:
      return Object.assign({}, state, {
        contests: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case contestsActionTypes.GET_CONTESTS_REJECTED:
      return Object.assign({}, state, {
        contests: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case contestsActionTypes.GET_CONTEST_PENDING:
      return Object.assign({}, state, {
        contest: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case contestsActionTypes.GET_CONTEST_FULFILLED:
      return Object.assign({}, state, {
        contest: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case contestsActionTypes.GET_CONTEST_REJECTED:
      return Object.assign({}, state, {
        contest: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case contestsActionTypes.GET_CONTEST_RESULT_PENDING:
      return Object.assign({}, state, {
        contestResult: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case contestsActionTypes.GET_CONTEST_RESULT_FULFILLED:
      return Object.assign({}, state, {
        contestResult: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case contestsActionTypes.GET_CONTEST_RESULT_REJECTED:
      return Object.assign({}, state, {
        contestResult: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case contestsActionTypes.JOIN_CONTEST_PENDING:
      return Object.assign({}, state, {
        joinContestData: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case contestsActionTypes.JOIN_CONTEST_FULFILLED:
      return Object.assign({}, state, {
        joinContestData: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case contestsActionTypes.JOIN_CONTEST_REJECTED:
      return Object.assign({}, state, {
        joinContestData: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    default:
      return state;
  }
};

export default contestsReducer;
