import React, { useEffect, useState } from "react";
import styled from "styled-components";
import forEach from "lodash/forEach";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import Notification from 'features/notifications/Notification';
import MomentDetailsDisplayCard from "features/moment/MomentDetailsDisplayCard";
import PlayersList from "features/players/PlayersList";
import Header from "features/header";
import VideoPlayer from "./VideoPlayer";
import Spinner from "shared/components/Spinner";

import { getContest } from "features/contests/contestsActions";
import { getHeaders } from "shared/apiHelper";
import { AppContainer, AppContent, Footer, NextButton } from "pages/commonStyles";

const PageContainer = styled(AppContent)`
  @media (max-width: 768px) {
  }
`;
const ContestContainer = styled.div``;
const VideoPlayerWrapper = styled.div`
  margin: 30px 15px;
`;
const MomentWrapper = styled.div`
  margin: 20px 40px;
`;
const PlayersListWrapper = styled.div``;
const SelectTeamWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px;
`;
const Team = styled.img`
  width: auto;
  height: 50px;
  max-width: 50px;
`;
const SelectTeamTitle = styled.h2`
  font-family: Druk;
  font-size: 10px;
  color: #ffffff;
  margin: 0 20px;
`;

function ContestPage() {
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { contest } = useSelector(({ contestsReducer }) => contestsReducer);
  const { token } = useSelector(({ authReducer }) => authReducer);
  const headers = getHeaders(token);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState("home");
  const playersMap = {};
  if (contest.data) {
    forEach(contest.data.players, player => {
      playersMap[player.id] = player;
    });
  }

  useEffect(() => {
    if (!hasFetchedData && !contest.data) {
      setHasFetchedData(true);
      dispatch(
        getContest({
          headers,
          params: {
            id: match.params.id
          }
        })
      );
    }
  }, [hasFetchedData, contest, match, dispatch, headers]);

  const onClickNext = () => {
    history.push({
      pathname: `/app/contest-selection/${match.params.id}`,
      state: {
        contest: contest.data,
        selectedPlayer: selectedPlayer.id,
        team: selectedTeam,
        replaced_at: selectedPosition
      }
    });
  };

  return (
    <AppContainer>
      <Header
        leftSection={
          <ArrowLeftOutlined
            style={{ color: "#0ce6bd", fontSize: "16px" }}
            onClick={() => {
              history.push("/app/contests");
            }}
          />
        }
        title="CONTEST"
        rightSection={<Notification />}
      />
      <PageContainer>
        {contest.data ? (
          <ContestContainer>
            <MomentWrapper>
              <MomentDetailsDisplayCard feed={contest.data.detail} />
            </MomentWrapper>

            <SelectTeamWrapper>
              <Team
                src={contest.data.detail.event_details.teams.home.logo}
                onClick={() => setSelectedTeam("home")}
              />
              <SelectTeamTitle>SELECT YOUR TEAM</SelectTeamTitle>
              <Team
                src={contest.data.detail.event_details.teams.away.logo}
                onClick={() => setSelectedTeam("away")}
              />
            </SelectTeamWrapper>
            <VideoPlayerWrapper>
              <VideoPlayer
                playerPositions={contest.data}
                players={playersMap}
                setSelectedPosition={setSelectedPosition}
                feed={contest.data.detail}
                selectedPlayer={selectedPlayer}
              />
            </VideoPlayerWrapper>
            <PlayersListWrapper>
              <PlayersList
                players={contest.data && contest.data.players}
                selectedPlayer={selectedPlayer}
                setSelectedPlayer={setSelectedPlayer}
              />
            </PlayersListWrapper>
          </ContestContainer>
        ) : <Spinner />}
      </PageContainer>
      <Footer>
        <NextButton
          disabled={!selectedPlayer}
          onClick={onClickNext}
        >
          NEXT
        </NextButton>
      </Footer>
    </AppContainer>
  );
}

export default ContestPage;
