import notificationsActionTypes from "./notificationsActionTypes";

const getNotifications = payload => {
  return {
    type: notificationsActionTypes.GET_NOTIFICATIONS,
    payload
  };
};

const updateNotifications = payload => {
  return {
    type: notificationsActionTypes.UPDATE_NOTIFICATIONS,
    payload
  };
};

export { getNotifications, updateNotifications };
