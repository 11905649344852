import React from "react";
import styled from "styled-components";
import Header from "features/header";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { notification } from "antd";

import Button from "shared/components/Button";
import { Input } from "shared/components/Input";
import { signin } from "features/auth/authActions";
import { Form, FormItem } from "shared/components/Form";
import snapifyIcon from "shared/assets/icons/Snapify_Logo@2x.png";
import facebookIcon from "shared/assets/icons/facebook_circle.png";
import googleIcon from "shared/assets/icons/google_circle.png";
import { AppContent, Footer } from "pages/commonStyles";

const CustomAppContent = styled(AppContent)`
  padding: 35px 15px;
`;
const StyledButton = styled(Button)`
  height: 100%;
`;
const SocialButtonsContainer = styled.div``;
const SocialButton = styled.img`
  height: 50px;
  width: 50px;
  margin-right: 20px;
`;
const OR = styled.h2`
  font-family: ProximaNova;
  font-weight: bold;
  font-size: 20px;
  margin: 17px auto;
  color: rgba(15, 230, 189);
`;
const LoginForm = styled(Form)`
  margin: 17px 0 0;
`;
const LoginFormItem = styled(FormItem)`
  margin: 0 30px 20px;
`;
const LoginButton = styled(Button)`
  height: 33px;
  margin: 50px 0 0 !important;
`;
const StyledInput = styled(Input)`
  background: rgba(215, 250, 243, 1);
`;

const SignIn = ({ history }) => {
  const dispatch = useDispatch();
  const { signinData } = useSelector(({ authReducer }) => authReducer);
  const handleSubmit = values => {
    const onSuccess = () => {
      history.push("/app/contests");
    };
    const onError = error => {
      notification.error({
        message: error
      });
    };
    dispatch(
      signin({
        email: values.email,
        password: values.password,
        onSuccess,
        onError
      })
    );
  };

  return (
    <React.Fragment>
      <Header
        leftSection={<img src={snapifyIcon} alt="" width="30px" />}
        title="LOG IN"
      />
      <CustomAppContent>
        <SocialButtonsContainer>
          <SocialButton
            src={facebookIcon}
            onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
          />
          <SocialButton
            src={googleIcon}
            onClick={() => Auth.federatedSignIn({ provider: "Google" })}
          />
        </SocialButtonsContainer>
        <OR>OR</OR>
        <LoginForm onFinish={handleSubmit} onFinishFailed={() => {}}>
          <LoginFormItem
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <StyledInput type="text" placeholder="Email" />
          </LoginFormItem>

          <LoginFormItem
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <StyledInput type="password" placeholder="Password" />
          </LoginFormItem>
          <LoginButton block htmlType="submit" loading={signinData.isPending}>LOG IN</LoginButton>
        </LoginForm>
      </CustomAppContent>
      <Footer>
        <StyledButton
          background="rgba(215, 250, 243, 1)"
          block
          onClick={() => {
            history.push("/signup");
          }}
        >
          Don't have an account? Sign up!
        </StyledButton>
      </Footer>
    </React.Fragment>
  );
};

export default withRouter(SignIn);
