import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";

import Notification from 'features/notifications/Notification';
import MomentDetailsDisplayCard from "features/moment/MomentDetailsDisplayCard";
import Header from "features/header";
import thumbnailLogo from "shared/assets/icons/video.png";
import changeIcon from "shared/assets/icons/change.png";
import stat1Icon from "shared/assets/icons/football-pitch-ball@2x.png";
import stat2Icon from "shared/assets/icons/goalie@2x.png";
import stat3Icon from "shared/assets/icons/jersey.png";

import { AppContent } from "pages/commonStyles";

const PageContainer = styled(AppContent)`
  height: calc(100vh - 60px);
  padding: 20px;
`;
const VideoCard = styled.div`
  background: rgba(9, 14, 46, 1);
`;
const TopOverlayWrapper = styled.div`
  position: absolute;
  background: rgba(9, 14, 46, 0.6);
  width: 100%;
  height: 70px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;
const LeagueDetails = styled.div`
  display: flex;
`;
const LeagueLogo = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 20px;
`;
const OverlayTitle = styled.h2`
  font-size: 12px;
  color: white;
  margin: 0;
  line-height: 2;
`;
const PlayerDetails = styled.div`
  display: flex;
  align-items: center;
`;
const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PlayerImage = styled.img`
  width: 35px;
  height: 35px;
`;
const PlayerName = styled.div`
  font-size: 8px;
  color: white;
`;
const SwapIcon = styled.img`
  height: 15px;
  width: 15px;
  margin: 0 10px;
`;
const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
`;
const Thumbnail = styled.img`
  position: relative;
  width: 100%;
  height: 250px;
`;
const VideoCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const ProgressBarWrapper = styled.div`
  width: 70%;
  background: grey;
  height: 10px;
  border-radius: 10px;
`;
const ProgressBar = styled.div`
  width: 40%;
  background: #0ce6bd;
  height: 10px;
  border-radius: 10px;
`;
const ProgressText = styled.h2`
  color: white;
  font-family: ProximaNova;
  font-weight: bold;
  font-size: 10px;
  margin: 10px 0 0;
`;
const StatsCard = styled.div`
  margin-top: 20px;
  padding: 20px;
  background: rgba(9, 14, 46, 1);
`;
const StatsTitle = styled.h2`
  font-size: 12px;
  letter-spacing: 1px;
  color: white;
  font-family: Druk;
  margin: 0 0 30px;
`;
const StatDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const StatImage = styled.img`
  width: 20px;
  height: 20px;
`;
const StatName = styled.h2`
  font-size: 10px;
  color: white;
  margin: 0 0 5px;
`;
const StatNumber = styled.h2`
  font-size: 10px;
  color: white;
  margin: 5px 0 0;
  font-family: Druk;
`;
const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MultiStatWrapper = styled.div`
  display: flex;
`;

const FinalPage = ({ history, location }) => {
  const firstPlayer = location.state.firstPlayer;
  const feed = location.state.feed;
  const secondPlayer = location.state.secondPlayer;
  return (
    <React.Fragment>
      <Header
        leftSection={
          <ArrowLeftOutlined
            style={{ color: "#0ce6bd", fontSize: "16px" }}
            onClick={() => {
              history.push("/app/feeds");
            }}
          />
        }
        title="REPLAY THE HISTORY"
        rightSection={<Notification />}
      />
      <PageContainer>
        <MomentDetailsDisplayCard feed={feed} />
        <VideoCard>
          <ThumbnailWrapper>
            <TopOverlayWrapper>
              <LeagueDetails>
                <LeagueLogo src={feed.event_details.league.logo} />
                <OverlayTitle>45s highlight</OverlayTitle>
              </LeagueDetails>
              <PlayerDetails>
                <PlayerWrapper>
                  <PlayerImage src={firstPlayer.image} />
                  <PlayerName>{`#${firstPlayer.jersey_number} ${firstPlayer.name}`}</PlayerName>
                </PlayerWrapper>
                <SwapIcon src={changeIcon} />
                <PlayerWrapper>
                  <PlayerImage src={secondPlayer.image} />
                  <PlayerName>{`#${secondPlayer.jersey_number} ${secondPlayer.name}`}</PlayerName>
                </PlayerWrapper>
              </PlayerDetails>
            </TopOverlayWrapper>
            <Thumbnail src={thumbnailLogo} />
          </ThumbnailWrapper>
          <VideoCardFooter>
            <ProgressBarWrapper><ProgressBar /></ProgressBarWrapper>
            <ProgressText>GENERATING YOUR VIDEO</ProgressText>
          </VideoCardFooter>
        </VideoCard>
        <StatsCard>
          <StatsTitle>THIS VIDEO IN THE PAST</StatsTitle>
          <StatsWrapper>
            <StatWrapper>
              <StatName>REPLAYED</StatName>
              <StatDetailWrapper>
                <StatImage src={stat1Icon} />
                <StatNumber>2K+</StatNumber>
              </StatDetailWrapper>
            </StatWrapper>
            <StatWrapper>
              <StatName>CHANGED</StatName>
              <StatDetailWrapper>
                <StatImage src={stat2Icon} />
                <StatNumber>567</StatNumber>
              </StatDetailWrapper>
            </StatWrapper>
            <StatWrapper>
              <StatName>MOST OFTEN</StatName>
              <MultiStatWrapper>
                <StatDetailWrapper>
                  <StatImage src={stat3Icon} />
                  <StatNumber>Messi</StatNumber>
                </StatDetailWrapper>
                <SwapIcon src={changeIcon} />
                <StatDetailWrapper>
                  <StatImage src={stat3Icon} />
                  <StatNumber>Ronaldo</StatNumber>
                </StatDetailWrapper>
              </MultiStatWrapper>
            </StatWrapper>
          </StatsWrapper>
        </StatsCard>
      </PageContainer>
    </React.Fragment>
  );
};

export default withRouter(FinalPage);
