import styled from 'styled-components';
import { Checkbox } from 'antd';

const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox-inner {
        background: ${props => props.theme.checkbox ? props.theme.checkbox.background : ''} !important;
        outline: none;
        border-color: ${props => props.theme.checkbox ? props.theme.checkbox.border : ''} !important;
    }
    .ant-checkbox:hover .ant-checkbox-inner {
        border-color: ${props => props.theme.checkbox ? props.theme.checkbox.border : ''} !important;
    }

    line-height: 20px !important;
    font-family: ProximaNova;
    font-weight: bold;
    font-size: 10px;
    color: #ffffff !important;
    text-align: left;
    margin: 10px 0 !important;
`;

export default StyledCheckbox;
