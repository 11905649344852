const preferencesActionTypes = {
    GET_SPORTS: 'GET_SPORTS',
    GET_SPORTS_PENDING: 'GET_SPORTS_PENDING',
    GET_SPORTS_FULFILLED: 'GET_SPORTS_FULFILLED',
    GET_SPORTS_REJECTED: 'GET_SPORTS_REJECTED',

    GET_SPORTS_INFO: 'GET_SPORTS_INFO',
    GET_SPORTS_INFO_PENDING: 'GET_SPORTS_INFO_PENDING',
    GET_SPORTS_INFO_FULFILLED: 'GET_SPORTS_INFO_FULFILLED',
    GET_SPORTS_INFO_REJECTED: 'GET_SPORTS_INFO_REJECTED',

    ADD_PREFERENCES: 'ADD_PREFERENCES',
    ADD_PREFERENCES_PENDING: 'ADD_PREFERENCES_PENDING',
    ADD_PREFERENCES_FULFILLED: 'ADD_PREFERENCES_FULFILLED',
    ADD_PREFERENCES_REJECTED: 'ADD_PREFERENCES_REJECTED',
};

export default preferencesActionTypes;
