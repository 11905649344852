import React from "react";
import styled from "styled-components";

import binIcon from "shared/assets/icons/bin.png";

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 1px solid #ffffff;
  align-items: center;
  margin: 5px 0;
  border-color: ${props => (props.selected ? "rgb(15, 230, 189)" : "#ffffff")};
`;
const ItemImage = styled.img`
  width: 40px;
  height: 40px;
`;
const ItemDetailsContainer = styled.div`
  display: flex;
`;
const ItemTitleContainer = styled.div`
  margin-left: 20px;
`;
const ItemTitle = styled.h2`
  font-family: ProximaNova;
  font-weight: bold;
  margin: 0;
  color: white;
  font-size: 14px;
`;
const ItemSubTitle = styled.h3`
  font-family: ProximaNova;
  font-weight: bold;
  margin: 0;
  color: white;
  font-size: 10px;
`;
const ItemAction = styled.img`
  width: auto;
  height: 20px;
`;

const Item = ({ item, onSelect, onRemove, isSelected, type }) => {
  return (
    <ItemContainer
      selected={isSelected}
      onClick={() => {
        if (!isSelected) {
          onSelect(item.id);
        }
      }}
    >
      <ItemDetailsContainer>
        <ItemImage src={item.icon} />
        <ItemTitleContainer>
          <ItemTitle>{item.name}</ItemTitle>
          <ItemSubTitle>
            {isSelected ? "Favourite" : "Select"} {type}
          </ItemSubTitle>
        </ItemTitleContainer>
      </ItemDetailsContainer>
      {isSelected ? (
        <ItemAction onClick={() => onRemove(item.id)} src={binIcon} />
      ) : null}
    </ItemContainer>
  );
};

export default Item;
