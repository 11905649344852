import { all } from "redux-saga/effects";
import authSagas from "features/auth/authSagas";
import preferencesSagas from "features/preferences/preferencesSagas";
import feedsSagas from "features/feeds/feedsSagas";
import accountsSagas from "features/accounts/accountsSagas";
import contestsSagas from "features/contests/contestsSagas";
import notificationsSagas from "features/notifications/notificationsSagas";

export default function* rootSaga() {
  yield all([
    authSagas(),
    preferencesSagas(),
    feedsSagas(),
    accountsSagas(),
    contestsSagas(),
    notificationsSagas()
  ]);
}
