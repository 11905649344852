import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import feedsActionTypes from "./feedsActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* getFeeds(action) {
  const {
    payload: { headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.FEEDS,
    headers: headers
  };

  yield put({ type: feedsActionTypes.GET_FEEDS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: feedsActionTypes.GET_FEEDS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: feedsActionTypes.GET_FEEDS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getFeedsSaga() {
  yield takeLatest(feedsActionTypes.GET_FEEDS, getFeeds);
}

export function* getVideos(action) {
  const {
    payload: { headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.MY_VIDEOS,
    headers: headers
  };

  yield put({ type: feedsActionTypes.GET_VIDEOS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: feedsActionTypes.GET_VIDEOS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: feedsActionTypes.GET_VIDEOS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getVideosSaga() {
  yield takeLatest(feedsActionTypes.GET_VIDEOS, getVideos);
}

export function* getPlayerPositions(action) {
  const {
    payload: { headers, params, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.PLAYER_POSITIONS,
    headers: headers,
    params: params
  };

  yield put({ type: feedsActionTypes.GET_PLAYER_POSITIONS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: feedsActionTypes.GET_PLAYER_POSITIONS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: feedsActionTypes.GET_PLAYER_POSITIONS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getPlayerPositionsSaga() {
  yield takeLatest(feedsActionTypes.GET_PLAYER_POSITIONS, getPlayerPositions);
}

export function* replacePlayer(action) {
  const {
    payload: { headers, params, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.REPLACE_PLAYER,
    headers: headers,
    params: params
  };

  yield put({ type: feedsActionTypes.REPLACE_PLAYER_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: feedsActionTypes.REPLACE_PLAYER_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: feedsActionTypes.REPLACE_PLAYER_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* replacePlayerSaga() {
  yield takeLatest(feedsActionTypes.REPLACE_PLAYER, replacePlayer);
}

export function* recreateVideo(action) {
  const {
    payload: { headers, data, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "POST",
    url: apiPaths.RECREATE_VIDEO,
    headers: headers,
    data: data
  };

  yield put({ type: feedsActionTypes.RECREATE_VIDEO_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: feedsActionTypes.RECREATE_VIDEO_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: feedsActionTypes.RECREATE_VIDEO_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* recreateVideoSaga() {
  yield takeLatest(feedsActionTypes.RECREATE_VIDEO, recreateVideo);
}

export default function* rootSaga() {
  yield all([
    fork(getFeedsSaga),
    fork(getPlayerPositionsSaga),
    fork(replacePlayerSaga),
    fork(recreateVideoSaga),
    fork(getVideosSaga)
  ]);
}
