import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background: rgba(113, 141, 152, 0.9) !important;
    overflow: scroll;
    width: ${props => props.width || "100%"};
    margin: 0 auto;
  }
  .ant-modal-body {
    padding: 0 !important;
    max-height: 550px;
    overflow: scroll;
  }
  .ant-modal-close-icon {
    color: #ffffff;
  }
`;

export default StyledModal;
