import feedsActionTypes from "./feedsActionTypes";

const initialState = {
  feeds: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  videos: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  playerPositions: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  replacePlayerList: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  recreateVideoData: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  }
};

const feedsReducer = (state = initialState, action) => {
  switch (action.type) {
    case feedsActionTypes.GET_FEEDS_PENDING:
      return Object.assign({}, state, {
        feeds: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case feedsActionTypes.GET_FEEDS_FULFILLED:
      return Object.assign({}, state, {
        feeds: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case feedsActionTypes.GET_FEEDS_REJECTED:
      return Object.assign({}, state, {
        feeds: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case feedsActionTypes.GET_VIDEOS_PENDING:
      return Object.assign({}, state, {
        videos: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case feedsActionTypes.GET_VIDEOS_FULFILLED:
      return Object.assign({}, state, {
        videos: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case feedsActionTypes.GET_VIDEOS_REJECTED:
      return Object.assign({}, state, {
        videos: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case feedsActionTypes.GET_PLAYER_POSITIONS_PENDING:
      return Object.assign({}, state, {
        playerPositions: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case feedsActionTypes.GET_PLAYER_POSITIONS_FULFILLED:
      return Object.assign({}, state, {
        playerPositions: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case feedsActionTypes.GET_PLAYER_POSITIONS_REJECTED:
      return Object.assign({}, state, {
        playerPositions: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case feedsActionTypes.REPLACE_PLAYER_PENDING:
      return Object.assign({}, state, {
        replacePlayerList: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case feedsActionTypes.REPLACE_PLAYER_FULFILLED:
      return Object.assign({}, state, {
        replacePlayerList: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case feedsActionTypes.REPLACE_PLAYER_REJECTED:
      return Object.assign({}, state, {
        replacePlayerList: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case feedsActionTypes.RECREATE_VIDEO_PENDING:
      return Object.assign({}, state, {
        recreateVideoData: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case feedsActionTypes.RECREATE_VIDEO_FULFILLED:
      return Object.assign({}, state, {
        recreateVideoData: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case feedsActionTypes.RECREATE_VIDEO_REJECTED:
      return Object.assign({}, state, {
        recreateVideoData: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    default:
      return state;
  }
};

export default feedsReducer;
