import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";

import Notification from 'features/notifications/Notification';
import MomentDetailsDisplayCard from "features/moment/MomentDetailsDisplayCard";
import Header from "features/header";
import PlayersList from "features/players/PlayersList";
import PlayersComparison from "features/players/PlayersComparison";

import { AppContent, AppContainer, Footer, NextButton } from "pages/commonStyles";

const PageContainer = styled(AppContent)`
  padding: 20px 125px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const MomentWrapper = styled.div`
  margin: 20px 40px;
`;
const PlayersComparisonWrapper = styled.div`
  margin: 20px;
`;
const PlayersListWrapper = styled.div`
  padding: 20px 0;
`;

const ReplayPage = ({ history, replacePlayerList, feed, recreateVideo }) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const player = replacePlayerList.main_player;

  const onClickNext = () => {
    const onSuccess = () => {
      history.push({
        pathname: "/app/final",
        state: {
          feed: feed,
          firstPlayer: player,
          secondPlayer: selectedPlayer
        }
      });
    };
    const onError = () => {};
    recreateVideo({
      data: {
        video_id: feed.id,
        highlight_player_id: player.id,
        substitute_player_id: selectedPlayer.id
      },
      onSuccess,
      onError
    });
  };
  return (
    <AppContainer>
      <Header
        leftSection={
          <ArrowLeftOutlined
            style={{ color: "#0ce6bd", fontSize: "16px" }}
            onClick={() => {
              history.push({
                pathname: "/app/recreate",
                state: {
                  feed: feed
                }
              });
            }}
          />
        }
        title="REPLAY THE HISTORY"
        rightSection={<Notification />}
      />
      <PageContainer>
        <MomentWrapper>
        <MomentDetailsDisplayCard feed={feed} />
        </MomentWrapper>
        <PlayersComparisonWrapper>
        <PlayersComparison player={player} selectedPlayer={selectedPlayer} />
        </PlayersComparisonWrapper>
        <PlayersListWrapper>
        <PlayersList
          players={replacePlayerList.substitute}
          selectedPlayer={selectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
        />
        </PlayersListWrapper>
      </PageContainer>
      <Footer>
        <NextButton
          disabled={!selectedPlayer}
          onClick={onClickNext}
        >
          NEXT
        </NextButton>
      </Footer>
    </AppContainer>
  );
};

export default withRouter(ReplayPage);
