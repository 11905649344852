import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import Notification from 'features/notifications/Notification';
import MomentDetailsDisplayCard from "features/moment/MomentDetailsDisplayCard";
import PlayersList from "features/players/PlayersList";
import PlayersComparison from "features/players/PlayersComparison";
import Header from "features/header";
import AddPoints from "./AddPoints";
import { getPackages } from "features/accounts/accountsActions";
import { joinContest, getContests } from "features/contests/contestsActions";
import { replacePlayer } from "features/feeds/feedsActions";
import { getHeaders } from "shared/apiHelper";
import {
  AppContainer,
  AppContent,
  Footer,
  BigEnterContestButton
} from "pages/commonStyles";

const PageContainer = styled(AppContent)`
  height: calc(100vh - 240px);
`;
const Foot = styled(Footer)`
  height: 180px;
`;
const ContestContainer = styled.div``;
const MomentWrapper = styled.div`
  margin: 20px 40px;
`;
const PlayersComparisonWrapper = styled.div`
  margin: 20px;
`;
const PlayersListWrapper = styled.div`
  padding: 20px 0;
`;

function ContestPage() {
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { replacePlayerList } = useSelector(({ feedsReducer }) => feedsReducer);
  const { token } = useSelector(({ authReducer }) => authReducer);
  const { packages } = useSelector(({ accountsReducer }) => accountsReducer);
  const headers = getHeaders(token);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [totalPoints, setTotalPoints] = useState(null);
  const [refillVisible, setRefillVisibility] = useState(false);

  useEffect(() => {
    if (
      !location.state.contest ||
      !location.state.selectedPlayer ||
      !location.state.team ||
      location.state.replaced_at === null
    ) {
      history.push("/app/contests");
    }
    if (!hasFetchedData && !replacePlayerList.data) {
      setHasFetchedData(true);
      dispatch(
        replacePlayer({
          headers,
          params: {
            id: location.state.selectedPlayer
          }
        })
      );
    }
  }, [hasFetchedData, replacePlayerList, headers, location, history, dispatch]);

  const onClickNext = () => {
    const onSuccess = res => {
      history.push(
        `/app/contest-results/${location.state.contest.detail.id}/${res.join_at}`
      );
      dispatch(
        getContests({
          headers
        })
      );
    };
    const onError = res => {
      if (res.err_msg === "Wallet is not enough.") {
        setTotalPoints(res.wallet);
        setRefillVisibility(true);
        dispatch(
          getPackages({
            headers
          })
        );
      }
    };
    dispatch(
      joinContest({
        headers,
        data: {
          contest_id: location.state.contest.detail.id,
          select_team: location.state.team,
          main_player_id: location.state.selectedPlayer,
          substitute_player_id: selectedPlayer.id,
          replaced_at: location.state.replaced_at
        },
        onSuccess,
        onError
      })
    );
  };

  return (
    <React.Fragment>
      <AppContainer>
        <Header
          leftSection={
            <ArrowLeftOutlined
              style={{ color: "#0ce6bd", fontSize: "16px" }}
              onClick={() => {
                history.push(
                  `/app/contest/${location.state.contest.detail.id}`
                );
              }}
            />
          }
          title="CONTEST"
          rightSection={<Notification />}
        />
        {replacePlayerList.data ? (
          <React.Fragment>
            <PageContainer>
              <ContestContainer>
                <MomentWrapper>
                  <MomentDetailsDisplayCard
                    feed={location.state.contest.detail}
                  />
                </MomentWrapper>
                <PlayersComparisonWrapper>
                  <PlayersComparison
                    player={{
                      ...replacePlayerList.data.main_player,
                      id: replacePlayerList.data.main_player.player_id
                    }}
                    selectedPlayer={selectedPlayer}
                  />
                </PlayersComparisonWrapper>
              </ContestContainer>
            </PageContainer>
            <Foot>
              <PlayersListWrapper>
                <PlayersList
                  players={replacePlayerList.data.substitute}
                  selectedPlayer={selectedPlayer}
                  setSelectedPlayer={setSelectedPlayer}
                />
              </PlayersListWrapper>
              <BigEnterContestButton disabled={!selectedPlayer} entryPoints={location.state.contest.detail.entry_points} onClick={onClickNext} />
            </Foot>
          </React.Fragment>
        ) : null}
        ;
      </AppContainer>
      <AddPoints
        visible={refillVisible}
        setVisibility={setRefillVisibility}
        points={totalPoints}
        packages={packages}
      />
    </React.Fragment>
  );
}

export default ContestPage;
