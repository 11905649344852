import styled from "styled-components";
import { AppContent } from "pages/commonStyles";
import Button from "shared/components/Button";

export const PageContainer = styled(AppContent)`
    height: calc(100vh - 60px);
`;
export const FeedsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const FeedCard = styled.div`
  width: calc(50% - 30px);
  position: relative;
  margin: 15px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
  }
`;
export const RecreateButton = styled(Button)`
  font-size: 10px;
`;
export const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
`;
export const TopOverlayWrapper = styled.div`
  display: ${props =>
    props.playerState && props.playerState.paused ? "flex" : "none"};
  background: rgba(9, 14, 46, 0.6);
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
`;
export const TopOverlayLeftSection = styled.div`
  display: flex;
  align-items: center;
`;
export const TopOverlayRightSection = styled.div`
  display: flex;
  align-items: center;
`;
export const Title = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
export const LeagueLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;
export const EventLogo = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;
export const EventTime = styled.h2`
  color: white;
  border: 1px solid white;
  border-radius: 100%;
  font-size: 10px;
  padding: 2px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
export const ActorName = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;

export const BottomOverlayWrapper = styled.div`
  display: ${props =>
    props.playerState && props.playerState.paused ? "flex" : "none"};
  background: rgba(9, 14, 46, 0.6);
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  top: calc(100% - 50px);
  position: absolute;
  width: 100%;
  z-index: 1;
`;

export const BottomOverlayLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const BottomOverlayRightSection = styled.div`
  display: flex;
  align-items: center;
`;
export const EventDate = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
export const EventDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const TeamLogo = styled.img`
  width: 20px;
  height: 20px;
`;
export const EventDetail = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0 10px;
  font-family: Druk;
`;
export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
`;
export const StatsImage = styled.img`
  width: 15px;
  height: 15px;
`;
export const StatsTitle = styled.h2`
  color: white;
  font-size: 8px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
export const StatsNumber = styled.h2`
  color: white;
  font-family: Druk;
  font-size: 10px;
  letter-spacing: 1px;
  margin: 0;
`;