import styled from "styled-components";
import { Input, Select } from "antd";
import Button from "shared/components/Button";

export const TitleSection = styled.div`
  margin: 20px 0;
`;
export const MainTitle = styled.h2`
  font-family: Druk;
  color: white !important;
  margin: 0;
`;
export const SubTitle = styled.h2`
  color: white !important;
  margin: 0;
  font-size: 14px;
  font-family: ProximaNova;
  font-weight: bold;
`;

export const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
`;
export const Card = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 50%;
  padding: 10px;
  border: 1px solid rgba(175, 175, 175, 1);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ItemTitle = styled.h2`
  color: white;
  margin: 0;
  font-size: 14px;
  font-family: ProximaNova;
  font-weight: bold;
  width: 100px;
  text-align: left;
`;
export const ItemImage = styled.img`
  width: auto;
  height: 80px;
`;
export const ItemButton = styled(Button)`
  width: 100px;
  font-size: 12px;
`;

export const ClubsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 100px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;
export const ClubCard = styled.div`
  width: calc(33.33% - 30px);
  background: rgba(255, 255, 255, 0.16);
  padding: 10px 0;
  margin: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: calc(50% - 30px);
  }
`;
export const ClubImage = styled.img`
  height: 150px;
  width: auto;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    height: 80px;
    width: auto;
  }
`;
export const SwitchTabName = styled.h2`
  font-family: ProximaNova;
  font-size: 10px;
  font-weight: bold;
  text-align: right;
  padding: 10px 30px 0;
  color: rgba(175, 175, 175, 1);
`;
export const ActionsContainer = styled.div`
  display: flex;
  margin: 15px 0;
  width: 100%;
  justify-content: space-between;
  padding: 0 115px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;
export const FilterIcon = styled.img`
  width: auto;
  height: 24px;
`;
export const SearchInput = styled(Input)`
  width: 130px;
  font-size: 10px;
  background: transparent;
  color: rgba(175, 175, 175, 1) !important;
  padding: 0;
  height: 24px;
  border-color: rgba(15, 230, 189, 1);
  text-align: center;
`;
export const SearchSelect = styled(Select)`
  width: 130px;
  font-size: 10px;
  background: transparent;

  .ant-select-selector {
    border-color: rgba(15, 230, 189, 1) !important;
    background: transparent !important;
    color: rgba(175, 175, 175, 1) !important;
  }

  .ant-select-selection-placeholder {
    opacity: 1;
  }

  .ant-select-arrow {
    color: rgba(15, 230, 189, 1) !important;
    opacity: 1;
  }
`;
export const SearchOption = styled(Select.Option)``;
