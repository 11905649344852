import styled from "styled-components";
import { Tabs as T } from "antd";

export const Tabs = styled(T)`
    background: rgba(9, 14, 46, 1) !important;
    .ant-tabs-tab {
        font-family: Druk;
        color: #FFFFFF;
        font-size: 10px;
    }

    .ant-tabs-tab-active {
        color: #FFFFFF !important;
    }

    .ant-tabs-ink-bar {
        border: 2px solid rgba(15, 230, 189, 1);
    }

    .ant-tabs-bar {
        border-color: transparent;
    }
`;
export const TabPane = styled(T.TabPane)``;
