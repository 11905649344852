import { combineReducers } from "redux";
import authReducer from "features/auth/authReducer";
import preferencesReducer from "features/preferences/preferencesReducer";
import feedsReducer from "features/feeds/feedsReducer";
import accountsReducer from "features/accounts/accountsReducer";
import contestsReducer from "features/contests/contestsReducer";
import notificationsReducer from "features/notifications/notificationsReducer";

const rootReducer = combineReducers({
  authReducer,
  preferencesReducer,
  feedsReducer,
  accountsReducer,
  contestsReducer,
  notificationsReducer
});

export default rootReducer;
