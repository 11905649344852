const contestsActionTypes = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_PENDING: "GET_NOTIFICATIONS_PENDING",
  GET_NOTIFICATIONS_FULFILLED: "GET_NOTIFICATIONS_FULFILLED",
  GET_NOTIFICATIONS_REJECTED: "GET_NOTIFICATIONS_REJECTED",
  
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  UPDATE_NOTIFICATIONS_PENDING: "UPDATE_NOTIFICATIONS_PENDING",
  UPDATE_NOTIFICATIONS_FULFILLED: "UPDATE_NOTIFICATIONS_FULFILLED",
  UPDATE_NOTIFICATIONS_REJECTED: "UPDATE_NOTIFICATIONS_REJECTED",
};

export default contestsActionTypes;
