import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import StripeCheckout from "react-stripe-checkout";
import { notification } from "antd";
import map from "lodash/map";

import Modal from "shared/components/Modal";
import Button from "shared/components/Button";
import Spinner from "shared/components/Spinner";
import walletIcon from "shared/assets/icons/wallet.png";
import { buyPackage } from "features/accounts/accountsActions";
import { getHeaders } from "shared/apiHelper";

const BuyPointsContainer = styled.div`
  padding: 30px 40px;
`;
const BuyPointsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BuyPointsTitle = styled.h2`
  font-family: Druk;
  color: #ffffff;
  font-size: 12px;
`;
const BuyPointsDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BuyPointsDataTitle = styled.h2`
  font-family: ProximaNova;
  color: #ffffff;
  font-size: 12px;
  margin: 0;
`;
const BuyPointsDataNumber = styled.h2`
  font-family: Druk;
  color: #ffffff;
  font-size: 14px;
  margin: 0;
`;
const PackagesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  min-height: 200px;
  align-items: center;
`;
const PackageContainer = styled.div`
  width: 50%;
  box-sizing: border-box;
`;
const PackageContent = styled.div`
  width: 100px;
  margin: 20px auto 0;
  height: 100px;
  background-color: rgba(9, 14, 46, 1);
  position: relative;
`;
const PriceContainer = styled.div`
  border-left: 50px solid rgba(9, 14, 46, 1);
  border-right: 0;
  border-top: 40px solid rgba(215, 250, 243, 1);
  position: absolute;
  right: 0;
  top: 0;
`;
const Price = styled.h2`
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 9px;
  font-family: ProximaNova;
  color: rgba(9, 14, 46, 1);
`;
const WalletIcon = styled.img`
  position: absolute;
  width: 35px;
  height: 35px;
  top: 15px;
  left: 32.5px;
`;
const PackageTitle = styled.h2`
  color: #ffffff;
  font-size: 13px;
  font-family: ProximaNova;
  position: absolute;
  top: 55px;
  width: 100%;
  text-align: center;
`;
const BuyButton = styled(Button)`
  position: absolute;
  top: 80px;
  height: 20px;
  font-size: 8px;
`;
const LessPointsTitle = styled.h2`
  font-family: ProximaNova;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  font-weight: bold;
`;

const AddPoints = ({ visible, setVisibility, points, packages }) => {
  const dispatch = useDispatch();
  const { token } = useSelector(({ authReducer }) => authReducer);
  const { buyPackageData } = useSelector(({ accountsReducer }) => accountsReducer);
  const headers = getHeaders(token);

  const renderPackages = () => {
    const handleSubscription = async (token, pack) => {
      const onSuccess = () => {
        notification.success({
          message: "Success"
        });
        setVisibility(false);
      };
      const onError = error => {
        notification.success({
          message: error
        });
        setVisibility(false);
      };

      dispatch(
        buyPackage({
          headers,
          data: {
            token_id: token.id,
            package_id: pack.id
          },
          onSuccess,
          onError
        })
      );
    };

    return (
      map(packages.data, (p, index) => {
        return (
          <PackageContainer key={index}>
            <PackageContent>
              <PriceContainer></PriceContainer>
              <Price>£{p.amount}</Price>
              <WalletIcon src={walletIcon} />
              <PackageTitle>{p.title}</PackageTitle>
              <StripeCheckout
                name="Replay"
                description={p.title}
                token={token => handleSubscription(token, p)}
                billingAddress={false}
                zipCode={false}
                panelLabel="Pay"
                stripeKey="pk_test_fz6IdE9TJUpGwoed2WiQgJQi"
              >
                <BuyButton block>BUY</BuyButton>
              </StripeCheckout>
            </PackageContent>
          </PackageContainer>
        );
      })
    );
  };

  return (
    <Modal
      width="320px"
      visible={visible}
      footer={null}
      onOk={() => {}}
      onCancel={() => {
        setVisibility(false);
      }}
    >
      <BuyPointsContainer>
          <React.Fragment>
            <BuyPointsHeader>
              <BuyPointsTitle>YOUR WALLET</BuyPointsTitle>
              <BuyPointsDataContainer>
                <BuyPointsDataNumber>{points}</BuyPointsDataNumber>
                <BuyPointsDataTitle>points</BuyPointsDataTitle>
              </BuyPointsDataContainer>
            </BuyPointsHeader>
            <LessPointsTitle>
                OPS, NOT ENOUGH POINTS!
                <br />
                PURCHASE MORE TO PROCEED.
            </LessPointsTitle>
            <PackagesContainer>
              {!packages.data || buyPackageData.isPending ?  <Spinner /> : renderPackages() }
            </PackagesContainer>
          </React.Fragment>
      </BuyPointsContainer>
    </Modal>
  );
};

export default AddPoints;
