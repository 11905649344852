import React, { useState } from "react";
import map from "lodash/map";
import uniq from "lodash/uniq";
import forEach from "lodash/forEach";
import filter from "lodash/filter";

import Modal from 'shared/components/Modal';

import {
  UpdateItemsContainer,
  UpdateContainer,
  UpdateTitle,
  NextButton,
  ChangeSection
} from "./styles";
import Item from "./Item";

const UpdatePreferences = ({
  visible,
  setVisibility,
  selectedSports,
  alreadySelectedLeagues,
  sportsInfo,
  addPreferences,
  refreshAccountDetails,
  onChange
}) => {
  const [selectedLeagues, setSelectedLeagues] = useState(
    alreadySelectedLeagues
  );
  const renderLeaguesSection = () => {
    const handleNext = () => {
      const onSuccess = () => {
        setVisibility(false);
        refreshAccountDetails();
      };
      const prefs = {};
      const leagues = filter(sportsInfo.data.leagues, league =>
        selectedLeagues.includes(league.id)
      );
      prefs["type"] = "LEAGUE";
      prefs["select"] = {};
      forEach(uniq(map(leagues, league => league.sport_id)), sport => {
        prefs["select"][sport] = map(
          filter(leagues, league => league.sport_id === sport),
          league => league.id
        );
      });

      addPreferences({
        data: {
          prefs
        },
        onSuccess
      });
    };

    const renderLeagues = (leagues) => {
      const removeLeague = leagueId => {
        setSelectedLeagues(
          filter(selectedLeagues, selectedLeague => selectedLeague !== leagueId)
        );
      };

      return map(leagues, league => {
        return (
          <Item
            key={league.id}
            item={league}
            type="Club"
            onSelect={id => setSelectedLeagues([...selectedLeagues, id])}
            onRemove={id => removeLeague(id)}
            isSelected={selectedLeagues.includes(league.id)}
          />
        );
      });
    };

    const renderSelectedLeagues = () => {
      const selected = filter(
        sportsInfo.data.leagues,
        league =>
          selectedSports.includes(league.sport_id) &&
          alreadySelectedLeagues.includes(league.id)
      );
      return renderLeagues(selected);
    };
    const renderUnSelectedLeagues = () => {
      const unSelected = filter(
        sportsInfo.data.leagues,
        league =>
          selectedSports.includes(league.sport_id) &&
          !alreadySelectedLeagues.includes(league.id)
      );
      return renderLeagues(unSelected);
    };

    return (
      <UpdateContainer>
        <ChangeSection onClick={onChange}>SELECT CLUB</ChangeSection>
        <UpdateTitle>YOUR LEAGUE</UpdateTitle>
        <UpdateItemsContainer>
          {renderSelectedLeagues()}
          {renderUnSelectedLeagues()}
        </UpdateItemsContainer>
        <NextButton block onClick={handleNext}>
          NEXT
        </NextButton>
      </UpdateContainer>
    );
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      onOk={() => {}}
      onCancel={() => {
        setVisibility(false);
      }}
    >
      {renderLeaguesSection()}
    </Modal>
  );
};

export default UpdatePreferences;
