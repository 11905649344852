import React, { useState } from "react";
import styled from "styled-components";
import { notification } from "antd";

import Button from "shared/components/Button";
import { Input } from "shared/components/Input";
import Modal from 'shared/components/Modal';

const UpdateNameContainer = styled.div`
  padding: 30px 40px;
`;
const UpdateNameTitle = styled.h2`
  font-family: Druk;
  color: #ffffff;
  font-size: 12px;
  width: 100%;
  text-align: center;
  margin: 0;
`;
const UpdateNameInput = styled(Input)`
  margin: 30px 0 15px;
`;
const UpdateNameButton = styled(Button)``;

const UpdateName = ({
  visible,
  setVisibility,
  account,
  editAccountDetails,
  refreshAccountDetails
}) => {
  const [name, setName] = useState((account.data && account.data.name) || "");

  const handleSubmit = () => {
    const onSuccess = () => {
      notification.success({
        message: "Success"
      });
      setVisibility(false);
      refreshAccountDetails();
    };
    const onError = error => {
      notification.success({
        message: error
      });
      setVisibility(false);
    };

    editAccountDetails({
      data: {
        name
      },
      onSuccess,
      onError
    });
  };
  return (
    <Modal
      width="320px"
      visible={visible}
      footer={null}
      onOk={() => {}}
      onCancel={() => {
        setVisibility(false);
      }}
    >
      <UpdateNameContainer>
        <UpdateNameTitle>UPDATE NAME</UpdateNameTitle>
        <UpdateNameInput value={name} onChange={e => setName(e.target.value)} />
        <UpdateNameButton block onClick={handleSubmit}>
          UPDATE
        </UpdateNameButton>
      </UpdateNameContainer>
    </Modal>
  );
};

export default UpdateName;
