import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { Spin } from "antd";
import { Player, BigPlayButton } from "video-react";

import stat1Logo from "shared/assets/icons/football-pitch-ball@2x.png";
import stat2Logo from "shared/assets/icons/goalie@2x.png";
import mute from "shared/assets/icons/mute.png";
import speaker from "shared/assets/icons/speaker.png";
import changeIcon from "shared/assets/icons/change.png";
import facebookIcon from "shared/assets/icons/fb_circle_white.png";
import twitterIcon from "shared/assets/icons/twitter_circle_white.png";

const VideoWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  flex-direction: column;
`;
const TopOverlayWrapper = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 50px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
`;
const TopOverlayLeftSection = styled.div`
  display: flex;
  align-items: center;
`;
const TopOverlayRightSection = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const LeagueLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;
const BottomOverlayWrapper = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.45);
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  width: 100%;
`;

const BottomOverlayLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BottomOverlayRightSection = styled.div`
  display: flex;
  align-items: center;
`;
const EventDate = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const EventDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TeamLogo = styled.img`
  width: 20px;
  height: 20px;
`;
const EventDetail = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0 10px;
  font-family: Druk;
`;
const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
`;
const StatsImage = styled.img`
  width: 15px;
  height: 15px;
`;
const StatsTitle = styled.h2`
  color: white;
  font-size: 8px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const StatsNumber = styled.h2`
  color: white;
  font-family: Druk;
  font-size: 10px;
  letter-spacing: 1px;
  margin: 0;
`;
const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PlayerImage = styled.img`
  width: 35px;
  height: 35px;
`;
const PlayerName = styled.div`
  font-size: 8px;
  color: white;
`;
const SwapIcon = styled.img`
  height: 15px;
  width: 15px;
  margin: 0 10px;
`;

const VideoPlayerWrapper = styled.div``;

export default class Video extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.video.video) {
      this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.visible === true && this.props.visible === true && this.state.player && !this.state.player.hasStarted) {
    //   this.player && this.player.play();
    // }
    if (prevProps.visible === false) {
      if (this.props.visible === true) {
        this.player && this.player.play();
      }
    } else {
      if (this.props.visible === false) {
        this.player && this.player.pause();
      }
    }
  }

  handleStateChange(state) {
    this.setState({
      player: state
    });
  }

  setMuted = muted => {
    this.player.muted = muted;
  };

  renderVideo = () => {
    if (this.props.video.video) {
      return (
        <VideoPlayerWrapper>
          <div
          style={{
            display: this.state.player && this.state.player.paused ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            position: "absolute",
            zIndex: 1,
            top: 'calc(50% - 40px)',
            left: '40%',
          }}
          >
            <img src={facebookIcon} alt="" width="40px" height="40px" />
            <img src={twitterIcon} alt="" width="40px" height="40px" style={{marginLeft: '20px'}} />
          </div>
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              top: "calc(100% - 140px)",
              left: "calc(100% - 60px)"
            }}
          >
            <img
              src={
                this.state.player && this.state.player.muted ? mute : speaker
              }
              width="40px"
              height="40px"
              alt=""
              onClick={() =>
                this.setMuted(this.state.player && !this.state.player.muted)
              }
            />
          </div>
          <Player
            autoPlay={this.props.visible}
            muted={true}
            src={this.props.video.video}
            // poster={this.props.video.thumbnail}
            ref={player => {
              this.player = player;
            }}
          >
            <BigPlayButton className="videoButton" position="center" />
          </Player>
        </VideoPlayerWrapper>
      );
    } else {
      return (
        <div>
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              background: "rgba(255, 255, 255, 0.3)",
              width: "100%",
              height: "calc(100% - 100px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spin style={{ color: "white" }} tip="Generating..." />
          </div>
          <img
            style={{ position: "relative" }}
            src={this.props.video.thumbnail}
            alt=""
            width="100%"
            height="auto"
          />
        </div>
      );
    }
  };

  render() {
    const { video } = this.props;
    return (
      <VideoWrapper>
        <TopOverlayWrapper playerState={this.state.player}>
          <TopOverlayLeftSection>
            <LeagueLogo src={video.event_details.league.logo} />
            <Title>{video.message}</Title>
          </TopOverlayLeftSection>
          <TopOverlayRightSection>
            <PlayerWrapper>
              <PlayerImage src={video.replacement.main_player.image} />
              <PlayerName>{`#${video.replacement.main_player.jersey_number} ${video.replacement.main_player.name}`}</PlayerName>
            </PlayerWrapper>
            <SwapIcon src={changeIcon} />
            <PlayerWrapper>
              <PlayerImage src={video.replacement.substitute_player.image} />
              <PlayerName>{`#${video.replacement.substitute_player.jersey_number} ${video.replacement.substitute_player.name}`}</PlayerName>
            </PlayerWrapper>
          </TopOverlayRightSection>
        </TopOverlayWrapper>
        {this.renderVideo()}
        <BottomOverlayWrapper playerState={this.state.player}>
          <BottomOverlayLeftSection>
            <EventDate>
              {moment
                .unix(video.event_details.date)
                .format("DD MMM YYYY")}
            </EventDate>
            <EventDetailsWrapper>
              <TeamLogo src={video.event_details.teams.home.logo} />
              <EventDetail>{video.event_details.result}</EventDetail>
              <TeamLogo src={video.event_details.teams.away.logo} />
            </EventDetailsWrapper>
          </BottomOverlayLeftSection>
          <BottomOverlayRightSection>
            <StatsWrapper>
              <StatsImage src={stat1Logo} />
              <StatsTitle>REPLAYED</StatsTitle>
              <StatsNumber>{video.stats.replayed}</StatsNumber>
            </StatsWrapper>
            <StatsWrapper>
              <StatsImage src={stat2Logo} />
              <StatsTitle>CHANGED</StatsTitle>
              <StatsNumber>{video.stats.changed}</StatsNumber>
            </StatsWrapper>
          </BottomOverlayRightSection>
        </BottomOverlayWrapper>
      </VideoWrapper>
    );
  }
}
