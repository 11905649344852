import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import accountsActionTypes from "./accountsActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* getAccountDetails(action) {
  const {
    payload: { headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.ACCOUNT,
    headers: headers
  };

  yield put({ type: accountsActionTypes.GET_ACCOUNT_DETAILS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: accountsActionTypes.GET_ACCOUNT_DETAILS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: accountsActionTypes.GET_ACCOUNT_DETAILS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getAccountDetailsSaga() {
  yield takeLatest(accountsActionTypes.GET_ACCOUNT_DETAILS, getAccountDetails);
}

export function* editAccountDetails(action) {
  const {
    payload: { headers, data, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "POST",
    url: apiPaths.EDIT_ACCOUNT,
    headers: headers,
    data: data
  };

  yield put({ type: accountsActionTypes.EDIT_ACCOUNT_DETAILS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: accountsActionTypes.EDIT_ACCOUNT_DETAILS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: accountsActionTypes.EDIT_ACCOUNT_DETAILS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* editAccountDetailsSaga() {
  yield takeLatest(accountsActionTypes.EDIT_ACCOUNT_DETAILS, editAccountDetails);
}

export function* getPackages(action) {
  const {
    payload: { headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.PACKAGES,
    headers: headers
  };

  yield put({ type: accountsActionTypes.GET_PACKAGES_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: accountsActionTypes.GET_PACKAGES_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: accountsActionTypes.GET_PACKAGES_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getPackagesSaga() {
  yield takeLatest(accountsActionTypes.GET_PACKAGES, getPackages);
}

export function* buyPackage(action) {
  const {
    payload: { headers, data, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "POST",
    url: apiPaths.BUY_PACKAGE,
    headers: headers,
    data: data
  };

  yield put({ type: accountsActionTypes.BUY_PACKAGE_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: accountsActionTypes.BUY_PACKAGE_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: accountsActionTypes.BUY_PACKAGE_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* buyPackageSaga() {
  yield takeLatest(accountsActionTypes.BUY_PACKAGE, buyPackage);
}

export default function* rootSaga() {
  yield all([
    fork(getAccountDetailsSaga),
    fork(editAccountDetailsSaga),
    fork(getPackagesSaga),
    fork(buyPackageSaga)
  ]);
}
