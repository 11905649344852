import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import filter from "lodash/filter";
import forEach from "lodash/forEach";

import Notification from 'features/notifications/Notification';
import VideoPlayer from "./VideoPlayer";
import Header from "features/header";
import PlayersList from "features/players/PlayersList";
import MomentDetailsDisplayCard from "features/moment/MomentDetailsDisplayCard";
import { AppContainer, AppContent, Footer, NextButton } from "pages/commonStyles";

const CustomAppContent = styled(AppContent)`
  padding: 20px 125px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
const VideoPlayerWrapper = styled.div`
  margin: 30px 15px;
`;
const MomentWrapper = styled.div`
  margin: 20px 40px;
`;
const PlayersListWrapper = styled.div``;

const RecreatePage = ({ feed, playerPositions, history }) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const players = filter(playerPositions.players, player => player.hightlight);
  const playersMap = {};
  forEach(playerPositions.players, player => {
    playersMap[player.id] = player;
  });

  const onClickNext = () => {
    history.push({
      pathname: "/app/replay",
      state: { selectedPlayer: selectedPlayer, feed: feed }
    });
  };

  return (
    <AppContainer>
      <Header
        leftSection={
          <ArrowLeftOutlined
            style={{ color: "#0ce6bd", fontSize: "16px" }}
            onClick={() => {
              history.push("/app/feeds");
            }}
          />
        }
        title="REPLAY THE HISTORY"
        rightSection={<Notification />}
      />
      <CustomAppContent>
        <MomentWrapper>
        <MomentDetailsDisplayCard feed={feed} />
        </MomentWrapper>
        <VideoPlayerWrapper>
        <VideoPlayer
          playerPositions={playerPositions}
          players={playersMap}
          feed={feed}
          selectedPlayer={selectedPlayer}
        />
        </VideoPlayerWrapper>
        <PlayersListWrapper>
        <PlayersList
          players={players}
          selectedPlayer={selectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
        />
        </PlayersListWrapper>
        
      </CustomAppContent>
      <Footer>
        <NextButton
          disabled={!selectedPlayer}
          onClick={onClickNext}
        >
          NEXT
        </NextButton>
      </Footer>
    </AppContainer>
  );
};

export default withRouter(RecreatePage);
