import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import NewPage from "./pages/new";
import FeedsPage from "./pages/feeds";
import RecreatePage from "./pages/recreate";
import ReplayPage from "./pages/replay";
import FinalPage from "./pages/final";
import VideosPage from "./pages/videos";
import AccountPage from "./pages/account";
import ContestsPage from "./pages/contests";
import ContestPage from "./pages/contests/ContestPage";
import ContestResultsPage from "./pages/contests/ContestResultsPage";
import ContestSelectionPage from "./pages/contests/ContestSelectionPage";

const MainApp = () => {
  let match = useRouteMatch();

  return (
      <Switch>
        <Route path={`${match.path}/new`} component={NewPage} />
        <Route path={`${match.path}/feeds`} component={FeedsPage} />
        <Route path={`${match.path}/recreate`} component={RecreatePage} />
        <Route path={`${match.path}/replay`} component={ReplayPage} />
        <Route path={`${match.path}/final`} component={FinalPage} />
        <Route path={`${match.path}/my-videos`} component={VideosPage} />
        <Route path={`${match.path}/account`} component={AccountPage} />

        <Route path={`${match.path}/contests`} component={ContestsPage} />
        <Route path={`${match.path}/contest/:id`} component={ContestPage} />
        <Route path={`${match.path}/contest-selection/:id`} component={ContestSelectionPage} />
        <Route path={`${match.path}/contest-results/:id/:date`} component={ContestResultsPage} />
      </Switch>
  );
};

export default MainApp;