import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getPlayerPositions } from "features/feeds/feedsActions";
import { getHeaders } from "shared/apiHelper";
import Spinner from 'shared/components/Spinner';

import RecreatePage from "./RecreatePage";

const mapStateToProps = state => ({
  playerPositions: state.feedsReducer.playerPositions,
  token: state.authReducer.token
});

const mapDispatchToProps = dispatch => ({
  getPlayerPositions: params => dispatch(getPlayerPositions(params))
});

class RecreatePageContainer extends React.PureComponent {
  static propTypes = {
    getPlayerPositions: PropTypes.func.isRequired,
    playerPositions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    token: PropTypes.object.isRequired
  };

  componentDidMount() {
    if (this.props.location.state.feed) {
      this.getPlayerPositions();
    }
  }

  getPlayerPositions = () => {
    const { token, getPlayerPositions, location } = this.props;
    const headers = getHeaders(token);

    getPlayerPositions({
      headers,
      params: {
        video_id: location.state.feed.id
      }
    });
  };

  render() {
    if (!this.props.location.state.feed) {
      this.props.history.push("/app/feeds");
    } else {
      if (this.props.playerPositions.isFulfilled) {
        return (
          <RecreatePage
            feed={this.props.location.state.feed}
            playerPositions={this.props.playerPositions.data}
            history={this.props.history}
          />
        );
      }
      return <Spinner />;
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecreatePageContainer)
);
