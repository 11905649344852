import { Slider } from "antd";
import styled from "styled-components";

const StyledSlider = styled(Slider)`
  margin: -5px 0 0;
  z-index: 2;
  .ant-slider-track {
    background: #ffffff !important;
  }
  .ant-slider-mark-text {
    display: none;
  }
  .ant-slider-rail {
    background: #0ce6bd !important;
  }
  .ant-slider-track {
    height: 10px !important;
  }
  .ant-slider-rail {
    height: 10px !important;
  }
  .ant-slider-handle {
    height: 50px;
    width: 50px;
    top: -12px;
    background: #0ce6bd !important;
    border-color: #000000 !important;
    @media (max-width: 768px) {
      height: 30px;
      width: 30px;
      top: -1px;
    }
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: black;
  }
  .ant-slider-dot {
    top: 1px;
    height: 8px;
    width: 8px;
    background: black;
    border-color: black;
  }
`;

export default StyledSlider;
