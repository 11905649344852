import accountsActionTypes from "./accountsActionTypes";

const initialState = {
  account: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  editAccountData: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  packages: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  buyPackageData: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
};

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountsActionTypes.GET_ACCOUNT_DETAILS_PENDING:
      return Object.assign({}, state, {
        account: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case accountsActionTypes.GET_ACCOUNT_DETAILS_FULFILLED:
      return Object.assign({}, state, {
        account: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case accountsActionTypes.GET_ACCOUNT_DETAILS_REJECTED:
      return Object.assign({}, state, {
        account: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case accountsActionTypes.EDIT_ACCOUNT_DETAILS_PENDING:
      return Object.assign({}, state, {
        editAccountData: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case accountsActionTypes.EDIT_ACCOUNT_DETAILS_FULFILLED:
      return Object.assign({}, state, {
        editAccountData: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case accountsActionTypes.EDIT_ACCOUNT_DETAILS_REJECTED:
      return Object.assign({}, state, {
        editAccountData: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case accountsActionTypes.GET_PACKAGES_PENDING:
      return Object.assign({}, state, {
        packages: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case accountsActionTypes.GET_PACKAGES_FULFILLED:
      return Object.assign({}, state, {
        packages: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case accountsActionTypes.GET_PACKAGES_REJECTED:
      return Object.assign({}, state, {
        packages: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case accountsActionTypes.BUY_PACKAGE_PENDING:
      return Object.assign({}, state, {
        buyPackageData: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case accountsActionTypes.BUY_PACKAGE_FULFILLED:
      return Object.assign({}, state, {
        buyPackageData: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case accountsActionTypes.BUY_PACKAGE_REJECTED:
      return Object.assign({}, state, {
        buyPackageData: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    default:
      return state;
  }
};

export default accountsReducer;
