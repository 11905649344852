import React, { Component } from "react";
import { Player, BigPlayButton } from "video-react";

export default class VideoPlayer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  handleStateChange(state) {
    this.setState({
      player: state
    });
  }

  render() {
    return (
      <Player
        ref={player => {
          this.player = player;
        }}
        src={this.props.video}
        poster={this.props.thumbnail}
        fluid={true}
        width="100%"
      >
        <BigPlayButton className="123" position="center" />
      </Player>
    );
  }
}
