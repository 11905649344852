import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
import {
  MenuOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  CompassOutlined
} from "@ant-design/icons";

import { signout } from 'features/auth/authActions';
import snapifyIcon from "shared/assets/icons/Snapify_Logo@2x.png";
import socialIcon from "shared/assets/icons/social.png";
import userIcon from "shared/assets/icons/user.png";

const DrawerContainer = styled.div`
background-color: #090e2e;
background-image: url("${require("shared/assets/icons/app_BG.webp")}");
background-size: cover;
    background-position: center top;
    padding: 25px;
height: 100vh;
box-sizing: border-box;
`;
const DrawerHeader = styled.div`
  height: 50px;
  box-sizing: border-box;
`;
const DrawerContent = styled.div`
  height: calc(100vh - 170px);
  box-sizing: border-box;
`;
const DrawerFooter = styled.div`
  height: 50px;
  box-sizing: border-box;
`;
const LogoContainer = styled.div`
  display: flex;
`;
const Logo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 20px;
`;
const AppName = styled.h2`
  margin: 0;
  font-size: 15px;
  color: white;
`;

const UserContainer = styled.div`
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
`;
const UserIcon = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 30px;
`;
const UserDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const UserName = styled.h2`
  font-family: Druk;
  font-size: 15px;
  color: white;
  margin: 0;
`;
// const UserDescription = styled.h2`
//   font-size: 14px;
//   color: white;
//   margin: 0;
// `;
const NavigationList = styled.ul`
  margin-top: 40px;
  margin-left: 10px;
  list-style: none;
  padding: 0;
`;
const NavigationListItem = styled.li`
  margin-top: 20px;
`;
const NavigationText = styled.span`
  color: white;
  font-size: 15px;
  margin-left: 20px;
`;
const SocialIconsContainer = styled.div`
  margin: 0 10px;
`;
const SocailIcon = styled.img`
  width: 100%;
`;

const NavigationMenu = () => {
  const dispatch = useDispatch();
  const [visible, setVisibility] = useState(false);
  const { account } = useSelector(({ accountsReducer }) => accountsReducer);
  return (
    <React.Fragment>
      <MenuOutlined
        style={{ color: "#0ce6bd", fontSize: "16px" }}
        onClick={() => {
          setVisibility(true);
        }}
      />
      <Drawer
        placement="left"
        closable={false}
        onClose={() => {
          setVisibility(false);
        }}
        visible={visible}
      >
        <DrawerContainer>
          <DrawerHeader>
            <LogoContainer>
              <Logo src={snapifyIcon} />
              <AppName>REPLAY THE HISTORY</AppName>
            </LogoContainer>
          </DrawerHeader>
          <DrawerContent>
            <UserContainer>
              <UserIcon src={userIcon} />
              <UserDetailsContainer>
                <UserName>
                  {account.data && account.data.name && account.data.name.toUpperCase()}
                </UserName>
                {/* <UserDescription>MCFC</UserDescription> */}
              </UserDetailsContainer>
            </UserContainer>
            <NavigationList>
              <NavigationListItem>
                <Link to="/app/account">
                  <UserOutlined style={{ color: "white", fontSize: "15px" }} />
                  <NavigationText>ACCOUNT</NavigationText>
                </Link>
              </NavigationListItem>
              <NavigationListItem>
                <Link to="/app/contests">
                  <CompassOutlined
                    style={{ color: "white", fontSize: "15px" }}
                  />
                  <NavigationText>CONTESTS</NavigationText>
                </Link>
              </NavigationListItem>
              <NavigationListItem>
                <Link to="/app/my-videos">
                  <VideoCameraOutlined
                    style={{ color: "white", fontSize: "15px" }}
                  />
                  <NavigationText>VIDEOS</NavigationText>
                </Link>
              </NavigationListItem>
              <NavigationListItem>
                <Link to="/app/feeds">
                  <CompassOutlined
                    style={{ color: "white", fontSize: "15px" }}
                  />
                  <NavigationText>EXPLORE</NavigationText>
                </Link>
              </NavigationListItem>
              <NavigationListItem onClick={() => {
                dispatch(signout());
              }}>
                <SettingOutlined style={{ color: "white", fontSize: "15px" }} />
                <NavigationText>LOG OUT</NavigationText>
              </NavigationListItem>
            </NavigationList>
          </DrawerContent>
          <DrawerFooter>
            <SocialIconsContainer>
              <SocailIcon src={socialIcon} />
            </SocialIconsContainer>
          </DrawerFooter>
        </DrawerContainer>
      </Drawer>
    </React.Fragment>
  );
};

export default NavigationMenu;
