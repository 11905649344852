import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getVideos } from 'features/feeds/feedsActions';
import { getHeaders } from 'shared/apiHelper';
import Spinner from 'shared/components/Spinner';

import VideosPage from './VideosPage';

const mapStateToProps = state => ({
    videos: state.feedsReducer.videos,
    token: state.authReducer.token,
});

const mapDispatchToProps = dispatch => ({
    getVideos: params => dispatch(getVideos(params)),
});

class VideosPageContainer extends React.PureComponent {
    static propTypes = {
        getVideos: PropTypes.func.isRequired,
        videos: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        token: PropTypes.object.isRequired,
    }

    componentDidMount() {
        this.getVideos();
    }

    getVideos = () => {
        const { token, getVideos } = this.props;
        const headers = getHeaders(token);
        getVideos({
            headers,
        });
    }

    render() {
        if (this.props.videos.data) {
            return (
                <VideosPage
                    videos={[...this.props.videos.data, ...this.props.videos.data, ...this.props.videos.data]}
                    history={this.props.history}
                />
            );
        }
        return <Spinner />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideosPageContainer));
