import React, { useState } from "react";
import { Link } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { notification } from "antd";
import map from "lodash/map";

import walletIcon from "shared/assets/icons/wallet.png";
import Modal from 'shared/components/Modal';
import {
  MomentContainer,
  MomentsContainer,
  MomentData,
  MomentTitle,
  BuyPointsContainer,
  BuyPointsHeader,
  BuyPointsTitle,
  BuyPointsDataContainer,
  BuyPointsDataTitle,
  BuyPointsDataNumber,
  PackagesContainer,
  PackageContainer,
  PackageContent,
  PriceContainer,
  Price,
  WalletIcon,
  PackageTitle,
  BuyButton
} from "./styles";

const WalletContainer = ({
  account,
  packages,
  buyPackage,
  refreshAccountDetails
}) => {
  const [visible, setVisibility] = useState(false);

  const renderPackages = () => {
    const handleSubscription = async (token, pack) => {
      const onSuccess = () => {
        notification.success({
          message: "Success"
        });
        setVisibility(false);
        refreshAccountDetails();
      };
      const onError = error => {
        notification.success({
          message: error
        });
        setVisibility(false);
        refreshAccountDetails();
      };

      buyPackage({
        data: {
          token_id: token.id,
          package_id: pack.id
        },
        onSuccess,
        onError
      });
    };

    return (
      packages.data &&
      map(packages.data, (p, index) => {
        return (
          <PackageContainer key={index}>
            <PackageContent>
              <PriceContainer></PriceContainer>
              <Price>£{p.amount}</Price>
              <WalletIcon src={walletIcon} />
              <PackageTitle>{p.title}</PackageTitle>
              <StripeCheckout
                name="Replay"
                description={p.title}
                token={token => handleSubscription(token, p)}
                billingAddress={false}
                zipCode={false}
                panelLabel="Subscribe"
                stripeKey="pk_test_fz6IdE9TJUpGwoed2WiQgJQi"
              >
                <BuyButton block>BUY</BuyButton>
              </StripeCheckout>
            </PackageContent>
          </PackageContainer>
        );
      })
    );
  };

  return (
    <MomentsContainer>
      <MomentContainer>
        <Link to="/app/my-videos">
          <MomentData>{account.data.replayed}</MomentData>
          <MomentTitle>moments replayed</MomentTitle>
        </Link>
      </MomentContainer>
      <MomentContainer onClick={() => setVisibility(true)}>
        <MomentData>{account.data.wallet}</MomentData>
        <MomentTitle>points</MomentTitle>
      </MomentContainer>
      <Modal
        width="320px"
        visible={visible}
        footer={null}
        onOk={() => {}}
        onCancel={() => {
          setVisibility(false);
        }}
      >
        <BuyPointsContainer>
          <BuyPointsHeader>
            <BuyPointsTitle>YOUR WALLET</BuyPointsTitle>
            <BuyPointsDataContainer>
              <BuyPointsDataNumber>{account.data.wallet}</BuyPointsDataNumber>
              <BuyPointsDataTitle>points</BuyPointsDataTitle>
            </BuyPointsDataContainer>
          </BuyPointsHeader>
          <PackagesContainer>{renderPackages()}</PackagesContainer>
        </BuyPointsContainer>
      </Modal>
    </MomentsContainer>
  );
};

export default WalletContainer;
