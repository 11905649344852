import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import contestsActionTypes from "./contestsActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* getContests(action) {
  const {
    payload: { headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.CONTESTS,
    headers: headers
  };

  yield put({ type: contestsActionTypes.GET_CONTESTS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: contestsActionTypes.GET_CONTESTS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: contestsActionTypes.GET_CONTESTS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getContestsSaga() {
  yield takeLatest(contestsActionTypes.GET_CONTESTS, getContests);
}

export function* getContest(action) {
  const {
    payload: { headers, params, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.CONTEST,
    headers: headers,
    params: params,
  };

  yield put({ type: contestsActionTypes.GET_CONTEST_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: contestsActionTypes.GET_CONTEST_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: contestsActionTypes.GET_CONTEST_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getContestSaga() {
  yield takeLatest(contestsActionTypes.GET_CONTEST, getContest);
}

export function* getContestResult(action) {
  const {
    payload: { headers, params, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.CONTEST_RESULT,
    headers: headers,
    params: params,
  };

  yield put({ type: contestsActionTypes.GET_CONTEST_RESULT_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: contestsActionTypes.GET_CONTEST_RESULT_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: contestsActionTypes.GET_CONTEST_RESULT_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getContestResultSaga() {
  yield takeLatest(contestsActionTypes.GET_CONTEST_RESULT, getContestResult);
}

export function* joinContest(action) {
  const {
    payload: { headers, data, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "POST",
    url: apiPaths.JOIN_CONTEST,
    headers: headers,
    data: data,
  };

  yield put({ type: contestsActionTypes.JOIN_CONTEST_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: contestsActionTypes.JOIN_CONTEST_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: contestsActionTypes.JOIN_CONTEST_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* joinContestSaga() {
  yield takeLatest(contestsActionTypes.JOIN_CONTEST, joinContest);
}

export default function* rootSaga() {
  yield all([
    fork(getContestsSaga),
    fork(getContestSaga),
    fork(getContestResultSaga),
    fork(joinContestSaga),
  ]);
}
