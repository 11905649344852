import React from "react";
import styled from "styled-components";

import changeIcon from "shared/assets/icons/change.png";
import soccerShirt from "shared/assets/icons/soccer_shirt.png";

const PlayerComparisonWrapper = styled.div``;
const PlayerInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;
const HighlightedPlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const HighlightedPlayerImageWrapper = styled.div`
  background: ${props => props.teamColor || "transparent"} !important;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HighlightedPlayerImage = styled.img`
  width: 70px;
  height: 70px;
`;
const HighlightedPlayerName = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
  font-family: ProximaNova;
`;
const ChangeIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const PlayerStatsWrapper = styled.div``;
const StatWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;
const StatName = styled.h2`
  font-size: 14px;
  color: rgba(175, 175, 175, 1);
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const StatIndex = styled.div`
  height: 20px;
  width: 100%;
  background: rgba(180, 180, 180, 1);
  display: flex;
`;
const FirstPlayerData = styled.div`
  width: 50%;
  height: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-right: 1px solid rgba(180, 180, 180, 1);
`;
const FirstPlayerNumber = styled.div`
  font-size: 12px;
  color: white;
  font-family: ProximaNova;
  margin-right: 10px;
  font-weight: bold;
`;
const FirstPlayerLine = styled.div`
  width: ${props => (props.value ? `${props.value * 0.8}%` : "0")} !important;
  height: 20px;
  background-image: linear-gradient(
    to right,
    rgba(15, 230, 189, 1),
    rgba(4, 52, 43, 1)
  );
`;
const SecondPlayerData = styled.div`
  width: 50%;
  height: 20px;
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(180, 180, 180, 1);
`;
const SecondPlayerNumber = styled.div`
  font-size: 12px;
  color: white;
  font-family: ProximaNova;
  margin-left: 10px;
  font-weight: bold;
`;
const SecondPlayerLine = styled.div`
  width: ${props => (props.value ? `${props.value * 0.8}%` : "0")} !important;
  height: 20px;
  background-image: linear-gradient(
    to right,
    rgba(9, 14, 46, 1),
    rgba(224, 228, 253, 1)
  );
`;

function PlayerComparison({ player, selectedPlayer }) {
  const renderHighlightedPlayer = player => {
    if (player) {
      return (
        <HighlightedPlayerWrapper>
          <HighlightedPlayerImageWrapper teamColor={player.color}>
            <HighlightedPlayerImage src={player.image} />
          </HighlightedPlayerImageWrapper>
          <HighlightedPlayerName>{`#${player.jersey_number} ${player.name}`}</HighlightedPlayerName>
        </HighlightedPlayerWrapper>
      );
    }
    return (
      <div
        style={{
          position: "relative",
          background: "rgba(0, 0, 0, 0.45)",
          width: "100px",
          height: "100px",
          borderRadius: "100%",
          alignItems: "center",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <span
          style={{
            position: "absolute",
            color: "white",
            fontSize: "10px",
            fontWeight: "bold",
            fontFamily: "ProximaNova"
          }}
        >
          SELECT A PLAYER
        </span>
        <img
          src={soccerShirt}
          alt=""
          width="70px"
          height="70px"
          style={{ opacity: "0.4" }}
        />
      </div>
    );
  };

  const renderStat = (statName, statKey, player1, player2) => {
    return (
      <StatWrapper>
        <StatName>{statName}</StatName>
        <StatIndex>
          <FirstPlayerData>
            <FirstPlayerLine value={player1 && player1.stats[statKey]} />
            <FirstPlayerNumber>{player1 && player1.stats[statKey]}</FirstPlayerNumber>
          </FirstPlayerData>
          <SecondPlayerData>
            <SecondPlayerLine value={player2 && player2.stats[statKey]} />
            <SecondPlayerNumber>
              {player2 && player2.stats[statKey]}
            </SecondPlayerNumber>
          </SecondPlayerData>
        </StatIndex>
      </StatWrapper>
    );
  };

  return (
    <PlayerComparisonWrapper>
      <PlayerInfoWrapper>
        {renderHighlightedPlayer(player)}
        <ChangeIcon src={changeIcon} />
        {renderHighlightedPlayer(selectedPlayer)}
      </PlayerInfoWrapper>
      <PlayerStatsWrapper>
        {renderStat("BALANCE", "balance", player, selectedPlayer)}
        {renderStat("BALL CONTROL", "ball_control", player, selectedPlayer)}
        {renderStat("FINISHING", "finishing", player, selectedPlayer)}
        {renderStat("HEADING", "heading", player, selectedPlayer)}
        {renderStat("LONG PASS", "long_pass", player, selectedPlayer)}
        {renderStat("POSSESION", "possesion", player, selectedPlayer)}
      </PlayerStatsWrapper>
    </PlayerComparisonWrapper>
  );
}

export default PlayerComparison;
