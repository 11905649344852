import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import notificationsActionTypes from "./notificationsActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* getNotifications(action) {
  const {
    payload: { headers, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "GET",
    url: apiPaths.GET_NOTIFICATIONS,
    headers: headers
  };

  yield put({ type: notificationsActionTypes.GET_NOTIFICATIONS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: notificationsActionTypes.GET_NOTIFICATIONS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: notificationsActionTypes.GET_NOTIFICATIONS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* getNotificationsSaga() {
  yield takeLatest(notificationsActionTypes.GET_NOTIFICATIONS, getNotifications);
}

export function* updateNotifications(action) {
  const {
    payload: { headers, data, onSuccess, onError }
  } = action;
  const apiConfig = {
    method: "POST",
    url: apiPaths.UPDATE_NOTIFICATIONS,
    headers: headers,
    data: data,
  };

  yield put({ type: notificationsActionTypes.UPDATE_NOTIFICATIONS_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({
      type: notificationsActionTypes.UPDATE_NOTIFICATIONS_FULFILLED,
      payload: response.data.data
    });
    yield put({
      type: authActionTypes.UPDATE_TOKEN,
      payload: response.data.access_token
    });
    onSuccess && onSuccess(response.data.data);
  } catch (e) {
    const statusCode = e && e.response && e.response.status;
    const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
    if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
      yield put({ type: authActionTypes.SIGNOUT });
    }
    yield put({
      type: notificationsActionTypes.UPDATE_NOTIFICATIONS_REJECTED,
      payload: errorMessage
    });
    onError && onError(errorMessage);
  }
}

export function* updateNotificationsSaga() {
  yield takeLatest(notificationsActionTypes.UPDATE_NOTIFICATIONS, updateNotifications);
}

export default function* rootSaga() {
  yield all([
    fork(getNotificationsSaga),
    fork(updateNotificationsSaga),
  ]);
}
