import styled from "styled-components";

export const Horizontal = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || "center"};
  margin: ${props => props.margin || "0"};
  padding: ${props => props.padding || "0"};
  width: ${props => props.width || "auto"};
`;
export const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${props => props.margin || "0"};
  padding: ${props => props.padding || "0"};
`;
