import notificationsActionTypes from "./notificationsActionTypes";

const initialState = {
  notifications: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  updateNotificationsData: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationsActionTypes.GET_NOTIFICATIONS_PENDING:
      return Object.assign({}, state, {
        notifications: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case notificationsActionTypes.GET_NOTIFICATIONS_FULFILLED:
      return Object.assign({}, state, {
        notifications: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case notificationsActionTypes.GET_NOTIFICATIONS_REJECTED:
      return Object.assign({}, state, {
        notifications: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case notificationsActionTypes.UPDATE_NOTIFICATIONS_PENDING:
      return Object.assign({}, state, {
        updateNotificationsData: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case notificationsActionTypes.UPDATE_NOTIFICATIONS_FULFILLED:
      return Object.assign({}, state, {
        updateNotificationsData: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case notificationsActionTypes.UPDATE_NOTIFICATIONS_REJECTED:
      return Object.assign({}, state, {
        updateNotificationsData: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    default:
      return state;
  }
};

export default notificationsReducer;
