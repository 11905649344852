import styled from "styled-components";
import { Tabs } from "antd";
import { AppContent } from "pages/commonStyles";

export const PageContainer = styled(AppContent)`
  height: calc(100vh - 60px);
`;
export const ContestsTab = styled(Tabs)``;
export const ContestsTabPane = styled(Tabs.TabPane)``;
export const ContestsContainer = styled.div`
  display: flex;
  height: calc(100vh - 148px);
  overflow-y: scroll;
  flex-wrap: wrap;
  margin: 15px 0;
  justify-content: center;
`;
export const Card = styled.div`
  width: calc(50% - 30px);
  position: relative;
  margin: 15px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 15px;
  }
`;
export const Button = styled.button`
  font-size: 10px;
  width: 100%;
  height: 35px;
  background: #0ce6bd;
  color: rgba(9, 14, 46, 1);
  border: none;
  padding: 5px 0;
  font-weight: bold;
  font-family: ProximaNova;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ContestPointsContainer = styled.div`
  position: relative;
  width: 25px;
  height: auto;
  margin-right: 5px;
`;
export const ContestPointsIcon = styled.img`
  position: relative;
  height: 25px;
  width: auto;
`;
export const ContestPoints = styled.span`
  color: #000000;
  position: absolute;
  top: 8px;
  left: 6px;
`;
export const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
`;
export const TopOverlayWrapper = styled.div`
  display: ${props =>
    props.playerState && props.playerState.paused ? "flex" : "none"};
  background: rgba(9, 14, 46, 0.6);
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
`;

export const BottomOverlayWrapper = styled.div`
  display: ${props =>
    props.playerState && props.playerState.paused ? "flex" : "none"};
  flex-direction: column;
  background: rgba(9, 14, 46, 0.6);
  justify-content: space-between;
  align-items: center;
  top: calc(100% - 64px);
  position: absolute;
  width: 100%;
  z-index: 1;
`;
export const PrizeOverlayWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
`;
export const PrizePoolWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const WinnerPoolWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const SpotsOverlayWrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const SpotsLeft = styled.div`
  width: 50%;
  text-align: left;
  background-image: linear-gradient(
    to right,
    rgba(2, 80, 65, 1),
    rgba(9, 14, 46, 1)
  );
  color: #ffffff;
  font-size: 10px;
  font-family: ProximaNova;
  font-weight: bold;
  padding: 0 10px;
`;
export const Spots = styled.div`
  width: 50%;
  text-align: left;
  color: rgba(9, 14, 46, 1);
  background: rgba(215, 250, 243, 1);
  font-size: 10px;
  font-family: ProximaNova;
  font-weight: bold;
  padding: 0 10px;
`;
export const PoolIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
`;
export const PoolIcon = styled.img`
  width: auto;
  height: 24px;
  margin: ${props => props.margin || '0'};
`;
export const PoolIconText = styled.h2`
  font-size: 10px;
  font-family: ProximaNova;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
`;
export const ReplayIcon = styled.img`
  height: 15px;
  width: auto;
  margin-right: 10px;
`;
export const Amount = styled.h2`
  font-size: 10px;
  font-family: Druk;
  color: #ffffff;
  margin: 0;
`;
