import accountsActionTypes from './accountsActionTypes';

const getAccountDetails = (payload) => {
    return {
        type: accountsActionTypes.GET_ACCOUNT_DETAILS,
        payload,
    };
};

const editAccountDetails = (payload) => {
    return {
        type: accountsActionTypes.EDIT_ACCOUNT_DETAILS,
        payload,
    };
};

const getPackages = (payload) => {
    return {
        type: accountsActionTypes.GET_PACKAGES,
        payload,
    };
};

const buyPackage = (payload) => {
    return {
        type: accountsActionTypes.BUY_PACKAGE,
        payload,
    };
};

export {
    getAccountDetails,
    editAccountDetails,
    getPackages,
    buyPackage
};
