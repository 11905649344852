import React from "react";
import map from "lodash/map";
import filter from "lodash/filter";

import Modal from 'shared/components/Modal';

import {
  UpdateItemsContainer,
  UpdateContainer,
  UpdateTitle,
  NextButton
} from "./styles";
import Item from "./Item";

const UpdatePreferences = ({
  visible,
  setVisibility,
  selectedSports,
  setSelectedSports,
  sports,
  onUpdateSports
}) => {
  const renderSportsSection = () => {
    const handleNext = () => {
      onUpdateSports();
    };

    const renderSports = (sports, isSelected) => {
      const removeSport = sportId => {
        setSelectedSports(
          filter(selectedSports, selectedSport => selectedSport !== sportId)
        );
      };

      return map(sports, (sport) => {
        return (
          <Item
            key={sport.sport_id}
            item={{ ...sport, id: sport.sport_id }}
            type="Sport"
            onSelect={id => setSelectedSports([...selectedSports, id])}
            onRemove={id => removeSport(id)}
            isSelected={isSelected}
          />
        );
      });
    };

    const renderSelectedSports = () => {
      const selected = filter(sports, sport =>
        selectedSports.includes(sport.sport_id)
      );
      return renderSports(selected, true);
    };
    const renderUnSelectedSports = () => {
      const unSelected = filter(
        sports,
        sport => !selectedSports.includes(sport.sport_id)
      );
      return renderSports(unSelected, false);
    };

    return (
      <UpdateContainer>
        <UpdateTitle>YOUR SPORT</UpdateTitle>
        <UpdateItemsContainer>
          {renderSelectedSports()}
          {renderUnSelectedSports()}
        </UpdateItemsContainer>
        <NextButton block onClick={handleNext}>
          NEXT
        </NextButton>
      </UpdateContainer>
    );
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      onOk={() => {}}
      onCancel={() => {
        setVisibility(false);
      }}
    >
      {renderSportsSection()}
    </Modal>
  );
};

export default UpdatePreferences;
