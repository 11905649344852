import styled from "styled-components";
import { Select } from "antd";
import Button from "shared/components/Button";
import { Input } from "shared/components/Input";
import { AppContent } from "pages/commonStyles";

export const PageContainer = styled(AppContent)`
  padding: 0 30px;
`;
export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 30px;
`;
export const UserDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const ProfileIcon = styled.img`
  height: 30px;
  width: 30px;
`;
export const UserName = styled.h2`
  font-family: Druk;
  font-size: 12px;
  color: #ffffff;
  margin: 0 0 0 20px;
  text-transform: uppercase;
`;
export const EditIcon = styled.img`
  width: 20px;
  height: 20px;
`;
export const PreferencesContainer = styled.div`
  margin-top: 40px;
`;
export const PreferencesHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const UpdateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;
export const UpdateTitle = styled.h2`
  font-family: Druk;
  font-size: 14px;
  color: white;
  margin: 16px 0;
`;
export const UpdateItemsContainer = styled.div`
  width: 100%;
`;
export const UpdateItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 1px solid #ffffff;
  align-items: center;
  margin: 5px 0;
  border-color: ${props => (props.selected ? "rgb(15, 230, 189)" : "#ffffff")};
`;
export const UpdateItemImage = styled.img`
  width: 40px;
  height: 40px;
`;
export const UpdateItemDetailsContainer = styled.div`
  display: flex;
`;
export const UpdateItemTitleContainer = styled.div`
  margin-left: 20px;
`;
export const UpdateItemTitle = styled.h2`
  font-family: ProximaNova;
  margin: 0;
  color: white;
  font-size: 14px;
`;
export const UpdateItemSubTitle = styled.h3`
  font-family: ProximaNova;
  margin: 0;
  color: white;
  font-size: 10px;
`;
export const UpdateItemAction = styled.img`
  width: auto;
  height: 20px;
`;
export const NextButton = styled(Button)`
  margin: 20px 0 0 !important;
`;
export const ChangeSection = styled.h2`
  font-size: 10px;
  color: #ffffff;
  font-family: ProximaNova;
  font-weight: bold;
  text-align: left;
  width: 100%;
`;

export const PreferencesHeader = styled.h2`
  font-size: 15px;
  font-family: ProximaNova;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
`;
export const ItemDetails = styled.div`
  display: flex;
`;
export const ItemIcon = styled.img`
  width: 40px;
  height: 40px;
`;
export const ItemTitleContainer = styled.div`
  margin-left: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
`;
export const ItemTitle = styled.h2`
  font-size: 14px;
  font-family: ProximaNova;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  text-align: left;
`;
export const ItemSubTitle = styled.h3`
  font-size: 10px;
  font-family: ProximaNova;
  font-weight: bold;
  color: rgb(175, 175, 175);
  margin: 0;
  text-align: left;
`;
export const ItemsContainer = styled.div`
  margin: 15px 0 0 30px;
`;
export const ItemContainer = styled.div`
  margin: 15px 0 0;
`;

export const MomentsContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const MomentContainer = styled.div`
  width: 50%;
  margin: 0 10px;
  box-sizing: border-box;
  height: 80px;
  background: rgba(180, 180, 180, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const MomentData = styled.h1`
  font-family: Druk;
  font-size: 15px;
  margin: 0;
  color: #ffffff;
`;
export const MomentTitle = styled.h2`
  font-family: ProximaNova;
  font-size: 13px;
  margin: 0;
  color: #ffffff;
`;

export const BuyPointsContainer = styled.div`
  padding: 30px 40px;
`;
export const BuyPointsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const BuyPointsTitle = styled.h2`
  font-family: Druk;
  color: #ffffff;
  font-size: 12px;
`;
export const BuyPointsDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const BuyPointsDataTitle = styled.h2`
  font-family: ProximaNova;
  color: #ffffff;
  font-size: 12px;
  margin: 0;
`;
export const BuyPointsDataNumber = styled.h2`
  font-family: Druk;
  color: #ffffff;
  font-size: 14px;
  margin: 0;
`;
export const PackagesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
export const PackageContainer = styled.div`
  width: 50%;
  box-sizing: border-box;
`;
export const PackageContent = styled.div`
  width: 100px;
  margin: 20px auto 0;
  height: 100px;
  background-color: rgba(9, 14, 46, 0.6);
  position: relative;
`;
export const PriceContainer = styled.div`
  border-left: 50px solid transparent;
  border-right: 0;
  border-top: 40px solid rgba(215, 250, 243, 1);
  position: absolute;
  right: 0;
  top: 0;
`;
export const Price = styled.h2`
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 9px;
  font-family: ProximaNova;
  font-weight: bold;
  color: rgba(9, 14, 46, 1);
`;
export const WalletIcon = styled.img`
  position: absolute;
  width: 35px;
  height: 35px;
  top: 15px;
  left: 32.5px;
`;
export const PackageTitle = styled.h2`
  color: #ffffff;
  font-size: 13px;
  font-family: ProximaNova;
  font-weight: bold;
  position: absolute;
  top: 55px;
  width: 100%;
  text-align: center;
`;
export const BuyButton = styled(Button)`
  position: absolute;
  top: 80px;
  height: 20px;
  font-size: 8px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 15px;
  justify-content: space-around;
`;
export const FilterIcon = styled.img`
  width: auto;
  height: 24px;
`;
export const SearchInput = styled(Input)`
  width: 130px;
  font-size: 10px;
  background: transparent;
  color: rgba(9, 14, 46, 1) !important;
  padding: 0;
  height: 24px;
  border-color: rgba(15, 230, 189, 1);
  text-align: center;
  ::placeholder {
    color: rgba(9, 14, 46, 1) !important;
  }
`;
export const SearchSelect = styled(Select)`
  width: 130px;
  font-size: 10px;
  background: transparent;

  .ant-select-selector {
    border-color: rgba(15, 230, 189, 1) !important;
    background: transparent !important;
    color: rgba(9, 14, 46, 1) !important;
  }

  .ant-select-selection-placeholder {
    opacity: 1;
  }

  .ant-select-arrow {
    color: rgba(15, 230, 189, 1) !important;
    opacity: 1;
  }
`;
export const SearchOption = styled(Select.Option)``;
