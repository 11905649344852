import React from "react";
import map from "lodash/map";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getSports,
  getSportsInfo,
  addPreferences
} from "features/preferences/preferencesActions";
import {
  getAccountDetails,
  getPackages,
  buyPackage,
  editAccountDetails
} from "features/accounts/accountsActions";
import Spinner from "shared/components/Spinner";
import { getHeaders } from "shared/apiHelper";

import AccountPage from "./AccountPage";

const mapStateToProps = state => ({
  sports: state.preferencesReducer.sports,
  packages: state.accountsReducer.packages,
  account: state.accountsReducer.account,
  sportsInfo: state.preferencesReducer.sportsInfo,
  token: state.authReducer.token,
  signinData: state.authReducer.signinData
});

const mapDispatchToProps = dispatch => ({
  buyPackage: params => dispatch(buyPackage(params)),
  editAccountDetails: params => dispatch(editAccountDetails(params)),
  getAccountDetails: params => dispatch(getAccountDetails(params)),
  getPackages: params => dispatch(getPackages(params)),
  getSports: params => dispatch(getSports(params)),
  getSportsInfo: params => dispatch(getSportsInfo(params)),
  addPreferences: params => dispatch(addPreferences(params))
});

class AccountPageContainer extends React.PureComponent {
  static propTypes = {
    buyPackage: PropTypes.func.isRequired,
    getAccountDetails: PropTypes.func.isRequired,
    editAccountDetails: PropTypes.func.isRequired,
    getPackages: PropTypes.func.isRequired,
    getSports: PropTypes.func.isRequired,
    getSportsInfo: PropTypes.func.isRequired,
    addPreferences: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    sports: PropTypes.object.isRequired,
    packages: PropTypes.object.isRequired,
    sportsInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    token: PropTypes.object.isRequired,
    signinData: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.getAccountDetails();
    this.getSports({
      onSuccess: this.getSportsOnSuccess
    });
    this.getPackages();
  }

  getSportsOnSuccess = response => {
    this.getSportsInfo({
      params: {
        ids: map(response, sport => sport.sport_id).join(",")
      }
    });
  };

  buyPackage = payload => {
    const { token, buyPackage } = this.props;
    const headers = getHeaders(token);
    buyPackage({
      headers,
      ...payload
    });
  };

  editAccountDetails = payload => {
    const { token, editAccountDetails } = this.props;
    const headers = getHeaders(token);
    editAccountDetails({
      headers,
      ...payload
    });
  };

  getPackages = () => {
    const { token, getPackages } = this.props;
    const headers = getHeaders(token);
    getPackages({
      headers
    });
  };

  getAccountDetails = () => {
    const { token, getAccountDetails } = this.props;
    const headers = getHeaders(token);
    getAccountDetails({
      headers
    });
  };

  getSports = payload => {
    const { token, getSports } = this.props;
    const headers = getHeaders(token);
    getSports({
      headers,
      ...payload
    });
  };

  getSportsInfo = payload => {
    const { token, getSportsInfo } = this.props;
    const headers = getHeaders(token);
    getSportsInfo({
      headers,
      params: payload.params,
      onSuccess: payload.onSuccess
    });
  };

  addPreferences = params => {
    const { token, addPreferences } = this.props;
    const headers = getHeaders(token);
    addPreferences({
      ...params,
      headers
    });
  };

  refreshAccountDetails = () => {
    this.getAccountDetails();
  };

  render() {
    const { sports, sportsInfo, account, packages } = this.props;
    if (sports.isFulfilled && sportsInfo.isFulfilled && account.data) {
      return (
        <AccountPage
          packages={packages}
          buyPackage={this.buyPackage}
          editAccountDetails={this.editAccountDetails}
          account={account}
          sports={sports.data}
          sportsInfo={sportsInfo}
          addPreferences={this.addPreferences}
          setPreferences={this.setPreferences}
          refreshAccountDetails={this.refreshAccountDetails}
        />
      );
    }
    return <Spinner />;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountPageContainer)
);
