import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import Notification from 'features/notifications/Notification';
import MomentDetailsDisplayCard from "features/moment/MomentDetailsDisplayCard";
import Header from "features/header";
import ContestResultsTabs from "./ContestResultsTabs";
import Spinner from "shared/components/Spinner";

import changeIcon from "shared/assets/icons/change.png";
import VideoPlayer from "features/video/VideosPlayer";
import { getContestResult } from "features/contests/contestsActions";
import { getHeaders } from "shared/apiHelper";
import { AppContainer, AppContent } from "pages/commonStyles";
import facebookIcon from "shared/assets/icons/fb_circle_white.png";
import twitterIcon from "shared/assets/icons/twitter_circle_white.png";

const PageContainer = styled(AppContent)`
  height: calc(100vh - 60px);
`;
const PageWrapper = styled.div``;
const MomentWrapper = styled.div`
  margin: 20px 40px;
`;

const VideoCard = styled.div`
  background: rgba(9, 14, 46, 1);
`;
const TopOverlayWrapper = styled.div`
  position: absolute;
  background: rgba(9, 14, 46, 0.6);
  width: 100%;
  height: 70px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;
const LeagueDetails = styled.div`
  display: flex;
`;
const OverlayTitle = styled.h2`
  font-size: 12px;
  color: white;
  margin: 0;
  line-height: 2;
`;
const PlayerDetails = styled.div`
  display: flex;
  align-items: center;
`;
const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PlayerImage = styled.img`
  width: 35px;
  height: 35px;
`;
const PlayerName = styled.div`
  font-size: 8px;
  color: white;
`;
const SwapIcon = styled.img`
  height: 15px;
  width: 15px;
  margin: 0 10px;
`;
const ThumbnailWrapper = styled.div`
  width: 100%;
  position: relative;
`;
const Thumbnail = styled.img`
  position: relative;
  width: 100%;
  height: auto;
`;
const VideoCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const ProgressBarWrapper = styled.div`
  width: 70%;
  background: grey;
  height: 10px;
  border-radius: 10px;
`;
const ProgressBar = styled.div`
  width: 40%;
  background: #0ce6bd;
  height: 10px;
  border-radius: 10px;
`;
const ProgressText = styled.h2`
  color: white;
  font-family: ProximaNova;
  font-weight: bold;
  font-size: 10px;
  margin: 10px 0 0;
`;
const ResultsWrapper = styled.div`
  margin: 10px;
`;
const ShareOverlayWrapper = styled.div`
  position: absolute;
  height: 80px;
  right: 0;
  top: 90px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const ShareIcon = styled.img`
  width: 30px;
  height: auto;
  margin-bottom: 10px;
`;

function ContestResultsPage() {
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { contestResult } = useSelector(
    ({ contestsReducer }) => contestsReducer
  );
  const { token } = useSelector(({ authReducer }) => authReducer);
  const headers = getHeaders(token);

  useEffect(() => {
    if (!match.params.id || !match.params.date) {
      history.push("/app/contests");
    }
    if (!hasFetchedData && !contestResult.data) {
      setHasFetchedData(true);
      dispatch(
        getContestResult({
          headers,
          params: {
            contest_id: match.params.id,
            join_at: match.params.date
          }
        })
      );
    }
  }, [hasFetchedData, contestResult, match, dispatch, headers, history]);

  const renderContestResult = () => {
    const {
      data: {
        contest_details,
        entry_details: {
          main_player,
          substitute_player,
          video_thumbnail,
          video_url,
          result,
          score_detail,
          score,
          rank,
          replaced_at
        }
      }
    } = contestResult;
    const renderCardContent = () => {
      if (video_url) {
        return <VideoPlayer video={video_url} thumbnail={video_thumbnail} />;
      }
      return <Thumbnail src={video_thumbnail} />;
    };
    const renderCardFooter = () => {
      if (!video_url) {
        return (
          <VideoCardFooter>
            <ProgressBarWrapper>
              <ProgressBar />
            </ProgressBarWrapper>
            <ProgressText>GENERATING YOUR VIDEO</ProgressText>
          </VideoCardFooter>
        );
      }
      return null;
    };
    return (
      <PageWrapper>
        <MomentWrapper>
          <MomentDetailsDisplayCard feed={contest_details} />;
        </MomentWrapper>
        <VideoCard>
          <ThumbnailWrapper>
            <TopOverlayWrapper>
              <LeagueDetails>
                <OverlayTitle>
                  Replaced on {parseInt(replaced_at)} s
                </OverlayTitle>
              </LeagueDetails>
              <PlayerDetails>
                <PlayerWrapper>
                  <PlayerImage src={main_player.image} />
                  <PlayerName>{`#${main_player.jersey_number} ${main_player.name}`}</PlayerName>
                </PlayerWrapper>
                <SwapIcon src={changeIcon} />
                <PlayerWrapper>
                  <PlayerImage src={substitute_player.image} />
                  <PlayerName>{`#${substitute_player.jersey_number} ${substitute_player.name}`}</PlayerName>
                </PlayerWrapper>
              </PlayerDetails>
            </TopOverlayWrapper>
            <ShareOverlayWrapper>
              <ShareIcon src={facebookIcon} />
              <ShareIcon src={twitterIcon} />
            </ShareOverlayWrapper>
            {renderCardContent()}
          </ThumbnailWrapper>
          {renderCardFooter()}
        </VideoCard>
        <ResultsWrapper>
          <ContestResultsTabs
            id={contest_details.contest_id}
            rankings={result}
            scores={score_detail}
            score={score}
            rank={rank}
          />
        </ResultsWrapper>
      </PageWrapper>
    );
  };

  return (
    <AppContainer>
      <Header
        leftSection={
          <ArrowLeftOutlined
            style={{ color: "#0ce6bd", fontSize: "16px" }}
            onClick={() => {
              history.push("/app/contests");
            }}
          />
        }
        title="RESULTS"
        rightSection={<Notification />}
      />
      <PageContainer>
        {contestResult.data ? renderContestResult() : <Spinner />}
      </PageContainer>
    </AppContainer>
  );
}

export default ContestResultsPage;
