import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Player, BigPlayButton, ControlBar } from "video-react";

import replayDark from "shared/assets/icons/replay_dark.png";
import replayWhite from "shared/assets/icons/replay_white.png";
import trophy from "shared/assets/icons/trophy.png";
import changeIcon from "shared/assets/icons/change.png";
import wallet from "shared/assets/icons/wallet.png";
import PrizeBreakup from "./PrizeBreakup";
import {
  Card,
  PlayerWrapper,
  TopOverlayWrapper,
  Button,
  ContestPointsContainer,
  ContestPointsIcon,
  ContestPoints,
  BottomOverlayWrapper,
  PrizeOverlayWrapper,
  PrizePoolWrapper,
  WinnerPoolWrapper,
  PoolIconWrapper,
  PoolIcon,
  PoolIconText,
  ReplayIcon,
  Amount,
  SpotsOverlayWrapper,
  SpotsLeft,
  Spots
} from "./styles";

const FlexHorizontalWrapper = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'center'};
  margin: ${props => props.margin || "0"};
  width: ${props => props.width || "auto"};
`;
const FlexVerticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'center'};
  margin: ${props => props.margin || "0"};
  width: ${props => props.width || "auto"};
`;
const Title = styled.h2`
  font-family: ProximaNova;
  color: white;
  margin: 0;
  font-size: ${props => (props.size === "small" ? "8px" : "12px")};
  font-weight: bold;
`;

const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
const EventLogo = styled.img`
  height: ${props => (props.size === "small" ? "10px" : "20px")};
  width: auto;
  margin: ${props => (props.size === "small" ? "0 5px" : "0 10px")};
`;
const EventTime = styled.h2`
  color: white;
  border: 1px solid white;
  border-radius: 100%;
  font-size: 12px;
  padding: 2px;
  margin: 0;
`;
const ActorName = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
`;

const TeamLogo = styled.img`
  width: 20px;
  height: 20px;
`;
const EventDetail = styled.h2`
  color: white;
  font-size: 8px;
  font-family: Druk;
  margin: 0 10px;
`;
const EndsIn = styled.h2`
  font-family: ProximaNova;
  font-size: 10px;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
`;
const EndsInTime = styled.h2`
  font-family: Druk;
  font-size: 10px;
  margin: 0;
  color: #ffffff;
`;
const PlayerImage = styled.img`
  height: 30px;
  width: auto;
`;
const ReplaceIcon = styled.img`
  height: 10px;
  width: auto;
`;

const MomentDetailsDisplayCard = ({ contest, type }) => {
  return (
    <DetailsWrapper>
      <FlexVerticleWrapper width="30%">
        <Title size="small">
          {moment.unix(contest.event_details.date).format("DD MMM YYYY")}
        </Title>
        <FlexHorizontalWrapper>
          <TeamLogo src={contest.event_details.teams.home.logo} />
          <EventDetail>{contest.event_details.result}</EventDetail>
          <TeamLogo src={contest.event_details.teams.away.logo} />
        </FlexHorizontalWrapper>
        {type === "joined" ? (
          <FlexHorizontalWrapper margin="5px 0 0">
            <Title size="small">{`${contest.moment_details.time}'`}</Title>
            <EventLogo size="small" src={contest.moment_details.icon} />
            <Title size="small">{contest.moment_details.player.name}</Title>
          </FlexHorizontalWrapper>
        ) : null}
      </FlexVerticleWrapper>
      {type === "joined" ? (
        <FlexHorizontalWrapper width="40%">
          <FlexVerticleWrapper margin="0 10px 0 0">
            <PlayerImage src={contest.entry_details.main_player.image} />
            <Title size="small">{contest.entry_details.main_player.name}</Title>
          </FlexVerticleWrapper>
          <ReplaceIcon src={changeIcon} />
          <FlexVerticleWrapper margin="0 0 0 10px">
            <PlayerImage src={contest.entry_details.substitute_player.image} />
            <Title size="small">
              {contest.entry_details.substitute_player.name}
            </Title>
          </FlexVerticleWrapper>
        </FlexHorizontalWrapper>
      ) : (
        <FlexHorizontalWrapper width="40%">
          <EventTime>{`${contest.moment_details.time}'`}</EventTime>
          <EventLogo src={contest.moment_details.icon} />
          <ActorName>{contest.moment_details.player.name}</ActorName>
        </FlexHorizontalWrapper>
      )}

      {moment().isAfter(moment.unix(contest.expiry_date)) ? (
        <FlexVerticleWrapper width="30%">
          <EndsIn>COMPLETED</EndsIn>
        </FlexVerticleWrapper>
      ) : (
        <FlexVerticleWrapper width="30%">
          <EndsIn>ENDS IN</EndsIn>
          <EndsInTime>
            {moment
              .unix(contest.expiry_date)
              .fromNow()
              .replace("in", "")
              .toUpperCase()}
          </EndsInTime>
        </FlexVerticleWrapper>
      )}
    </DetailsWrapper>
  );
};

class Contest extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      prizeBreakupVisibile: false
    };
  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  handleStateChange(state) {
    this.setState({
      player: state
    });
  }

  onClickRecreate = contest => {
    this.props.history.push(`/app/contest/${contest.id}`);
  };

  setPrizeBreakupVisibility = visible => {
    this.setState({
      prizeBreakupVisibile: visible
    });
  };

  renderContestAction = () => {
    const { contest, type, history } = this.props;
    if (type === "joined" && moment().isAfter(moment.unix(contest.expiry_date))) {

      return (
        <Button
          type="primary"
          onClick={() => {
            history.push(`/app/contest-results/${contest.id}/${contest.entry_details.join_at}`);
          }}
        >
          CONTEST DETAILS
        </Button>
      );
    }
    return (
      <Button type="primary" onClick={() => this.onClickRecreate(contest)}>
        <ContestPointsContainer>
          <ContestPointsIcon src={replayDark} />
          <ContestPoints>{contest.entry_points}</ContestPoints>
        </ContestPointsContainer>
        {type === "joined" ? "ENTER AGAIN" : "ENTER CONTEST"}
      </Button>
    );
  };

  render() {
    const { contest } = this.props;
    return (
      <React.Fragment>
        <Card>
          <PlayerWrapper>
            <TopOverlayWrapper playerState={this.state.player}>
              <MomentDetailsDisplayCard
                contest={contest}
                type={this.props.type}
              />
            </TopOverlayWrapper>
            <BottomOverlayWrapper playerState={this.state.player}>
              <PrizeOverlayWrapper>
                <PrizePoolWrapper>
                  <PoolIconWrapper
                    onClick={() => this.setPrizeBreakupVisibility(true)}
                  >
                    <PoolIcon src={wallet} />
                    <PoolIconText>PRIZE POOL</PoolIconText>
                  </PoolIconWrapper>
                  <ReplayIcon src={replayWhite} />
                  <Amount>{contest.prize_pool.toLocaleString()}</Amount>
                </PrizePoolWrapper>
                <WinnerPoolWrapper>
                  <PoolIconWrapper>
                    <FlexHorizontalWrapper>
                    <PoolIcon src={trophy} margin={contest.entry_details && contest.entry_details.rank && contest.entry_details.rank !== "TBD" ? '0 10px 0 0' : '0'} />
                    {contest.entry_details && contest.entry_details.rank && contest.entry_details.rank !== "TBD"  ? <Amount>{`#${contest.entry_details.rank.toLocaleString()}`}</Amount> : null}
                    </FlexHorizontalWrapper>
                    {contest.entry_details && contest.entry_details.rank && contest.entry_details.rank !== "TBD" ? <PoolIconText>YOUR RANK</PoolIconText> : null}
                  </PoolIconWrapper>
                  <ReplayIcon src={replayWhite} />
                  <Amount>{contest.first_prize.toLocaleString()}</Amount>
                </WinnerPoolWrapper>
              </PrizeOverlayWrapper>
              <SpotsOverlayWrapper>
                <SpotsLeft>Spots left</SpotsLeft>
                <Spots>{`${contest.spots_filled.toLocaleString()}/${contest.total_spots.toLocaleString()}`}</Spots>
              </SpotsOverlayWrapper>
            </BottomOverlayWrapper>
            <Player
              src={
                this.props.type === "joined"
                  ? contest.entry_details.video_url
                  : this.props.contest.video_url
              }
              poster={
                this.props.type === "joined"
                  ? contest.entry_details.video_thumbnail
                  : this.props.contest.thumbnail
              }
              fluid={true}
              width="100%"
              ref={player => {
                this.player = player;
              }}
            >
              <BigPlayButton className={this.state.player && this.state.player.paused ? 'show-play-button' : 'hide-play-button'} position="center" />
              <ControlBar className={this.state.player && this.state.player.paused ? 'hide-controls' : ''} />
            </Player>
          </PlayerWrapper>
          {this.renderContestAction()}
        </Card>
        <PrizeBreakup
          visible={this.state.prizeBreakupVisibile}
          setVisibility={this.setPrizeBreakupVisibility}
          breakup={contest.prize_breakup}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Contest);
