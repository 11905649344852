import React, { useRef } from "react";
import map from "lodash/map";
import styled from "styled-components";

const PlayersWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  height: 90px;
  align-items: flex-end;
  -webkit-overflow-scrolling: touch;
`;
const PlayerWrapper = styled.div`
  flex: 0 0 auto;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PlayerName = styled.h2`
  color: white;
  font-size: 10px !important;
  margin: 5px 0 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const PlayingStyle = styled.h2`
  color: white;
  font-size: 8px !important;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const PlayerImageWrapper = styled.div`
  background: ${props => props.teamColor || "rgba(59, 190, 229, 1)"} !important;
  border-radius: 100%;
  width: ${props => (props.active ? "70px" : "40px")} !important;
  height: ${props => (props.active ? "70px" : "40px")} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s, height 0.3s;
  transition-timing-function: linear;
`;
const PlayerImage = styled.img`
  width: ${props => (props.active ? "50px" : "30px")} !important;
  height: ${props => (props.active ? "50px" : "30px")} !important;
  transition: width 0.3s, height 0.3s;
  transition-timing-function: linear;
`;

function PlayersList({ players, selectedPlayer, setSelectedPlayer }) {
  let refs = [];
  const playersRef = useRef();
  const renderPlayers = () => {
    const onClickPlayer = (player, index) => {
      setSelectedPlayer(player);
      if (refs[index].current.offsetLeft > window.screen.width / 2) {
        playersRef.current.scrollLeft =
          refs[index].current.offsetLeft -
          window.screen.width / 2 +
          refs[index].current.offsetWidth / 2;
      }
    };

    return map(players, (player, index) => {
      refs[index] = React.createRef();
      return (
        <PlayerWrapper
          ref={refs[index]}
          key={index}
          onClick={() => onClickPlayer(player, index)}
        >
          <PlayerImageWrapper
            active={selectedPlayer && selectedPlayer.id === player.id}
            teamColor={player.color}
          >
            <PlayerImage
              active={selectedPlayer && selectedPlayer.id === player.id}
              src={player.image}
            />
          </PlayerImageWrapper>
          <PlayerName
            active={selectedPlayer && selectedPlayer.id === player.id}
          >{`#${player.jersey_number} ${player.name}`}</PlayerName>
          <PlayingStyle active={selectedPlayer && selectedPlayer.id === player.id}>
            {player.style}
          </PlayingStyle>
        </PlayerWrapper>
      );
    });
  };

  return <PlayersWrapper ref={playersRef}>{renderPlayers()}</PlayersWrapper>;
}

export default PlayersList;
