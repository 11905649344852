import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { notification } from "antd";
import map from "lodash/map";
import filter from "lodash/filter";
import uniq from "lodash/uniq";
import forEach from "lodash/forEach";

import Header from "features/header";
import filterIcon from "shared/assets/icons/filter.png";
import { AppContainer, AppContent, Footer, NextButton } from "pages/commonStyles";
import {
  TitleSection,
  MainTitle,
  SubTitle,
  Card,
  ItemButton,
  ItemTitle,
  ItemImage,
  SwitchTabName,
  ClubCard,
  ClubImage,
  ClubsSection,
  ActionsContainer,
  FilterIcon,
  SearchInput,
  SearchSelect,
  SearchOption,
  ItemList
} from "./styles";

const LeaguesAndClubs = ({
  sportsInfo,
  setCurrentTab,
  addPreferences,
  history
}) => {
  const [tab, setTab] = useState("clubs");
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");

  const submitPreferences = type => {
    const onSuccess = () => {
      history.push("/app/contests");
    };
    const onError = error => {
      notification.error({
        message: error
      });
    };
    const prefs = {};
    if (type === "teams") {
      const teams = filter(sportsInfo.data.teams, team =>
        selectedTeams.includes(team.id)
      );
      prefs["type"] = "TEAM";
      prefs["select"] = {};
      forEach(uniq(map(teams, team => team.sport_id)), sport => {
        prefs["select"][sport] = map(
          filter(teams, team => team.sport_id === sport),
          team => team.id
        );
      });
    } else {
      const leagues = filter(sportsInfo.data.leagues, league =>
        selectedLeagues.includes(league.id)
      );
      prefs["type"] = "LEAGUE";
      prefs["select"] = {};
      forEach(uniq(map(leagues, league => league.sport_id)), sport => {
        prefs["select"][sport] = map(
          filter(leagues, league => league.sport_id === sport),
          league => league.id
        );
      });
    }
    addPreferences({
      data: {
        prefs: prefs
      },
      onSuccess,
      onError
    });
  };

  const renderLeagues = () => {
    const handleSelectedLeagues = league => {
      if (selectedLeagues.includes(league.id)) {
        setSelectedLeagues(
          filter(
            selectedLeagues,
            selectedLeague => selectedLeague !== league.id
          )
        );
      } else {
        setSelectedLeagues([...selectedLeagues, league.id]);
      }
    };
    const renderLeagues = () => {
      return map(sportsInfo.data.leagues, (league, index) => {
        return (
          <Card key={index}>
            <ItemTitle>{league.name}</ItemTitle>
            <ItemImage src={league.icon} alt="" />
            <ItemButton
              type={
                selectedLeagues.includes(league.id) ? "secondary" : "primary"
              }
              onClick={() => handleSelectedLeagues(league)}
            >
              {selectedLeagues.includes(league.id) ? "FOLLOWING" : "FOLLOW"}
            </ItemButton>
          </Card>
        );
      });
    };
    return (
      <React.Fragment>
        <AppContent>
          <SwitchTabName
            onClick={() => {
              setSelectedLeagues([]);
              setTab("clubs");
            }}
          >
            SELECT CLUB
          </SwitchTabName>
          <TitleSection>
            <MainTitle>LEAGUES</MainTitle>
            <SubTitle>Pick your favourite leagues</SubTitle>
          </TitleSection>
          <ItemList>{renderLeagues()}</ItemList>
        </AppContent>
        <Footer>
          <NextButton
            disabled={selectedLeagues.length === 0}
            onClick={() => {
              submitPreferences("leagues");
            }}
          >
            NEXT
          </NextButton>
        </Footer>
      </React.Fragment>
    );
  };

  const renderClubs = () => {
    const countries = uniq(map(sportsInfo.data.teams, team => team.country));
    const handleSelectedTeams = team => {
      if (selectedTeams.includes(team.id)) {
        setSelectedTeams(
          filter(selectedTeams, selectedTeam => selectedTeam !== team.id)
        );
      } else {
        setSelectedTeams([...selectedTeams, team.id]);
      }
    };
    const handleSearchByNameChange = e => {
      setName(e.target.value);
    };
    const handleSearchByCountryChange = value => {
      setCountry(value);
    };
    const renderTeams = () => {
      let filteredTeams = sportsInfo.data.teams;
      if (country) {
        filteredTeams = filter(filteredTeams, team => team.country === country);
      }
      if (name) {
        filteredTeams = filter(filteredTeams, team =>
          team.name.toLowerCase().includes(name.toLowerCase())
        );
      }
      return map(filteredTeams, (team, index) => {
        return (
          <ClubCard key={index}>
            <ClubImage src={team.icon} />
            <ItemButton
              type={selectedTeams.includes(team.id) ? "secondary" : "primary"}
              onClick={() => handleSelectedTeams(team)}
            >
              {selectedTeams.includes(team.id) ? "FOLLOWING" : "FOLLOW"}
            </ItemButton>
          </ClubCard>
        );
      });
    };
    return (
      <React.Fragment>
        <AppContent>
          <SwitchTabName
            onClick={() => {
              setSelectedTeams([]);
              setTab("leagues");
            }}
          >
            SELECT LEAGUE
          </SwitchTabName>
          <TitleSection>
            <MainTitle>CLUB</MainTitle>
            <SubTitle>Pick your favourite club(s)</SubTitle>
          </TitleSection>
          <ActionsContainer>
            <FilterIcon src={filterIcon} />
            <SearchInput
              placeholder="Search by name"
              onChange={handleSearchByNameChange}
            />
            <SearchSelect
              placeholder="Search by country"
              onChange={handleSearchByCountryChange}
              size="small"
            >
              {map(countries, (country, index) => (
                <SearchOption key={index} value={country}>
                  {country}
                </SearchOption>
              ))}
            </SearchSelect>
          </ActionsContainer>
          <ClubsSection>{renderTeams()}</ClubsSection>
        </AppContent>
        <Footer>
          <NextButton
            disabled={selectedTeams.length === 0}
            onClick={() => {
              submitPreferences("teams");
            }}
          >
            NEXT
          </NextButton>
        </Footer>
      </React.Fragment>
    );
  };

  if (sportsInfo.isFulfilled) {
    return (
      <AppContainer>
        <Header
          leftSection={
            <ArrowLeftOutlined
              style={{ color: "#0ce6bd", fontSize: "16px" }}
              onClick={() => {
                setCurrentTab("s");
              }}
            />
          }
          title="CREATE ACCOUNT"
        />
        {tab === "leagues" ? renderLeagues() : renderClubs()}
      </AppContainer>
    );
  } else {
    return <div />;
  }
};

export default withRouter(LeaguesAndClubs);
