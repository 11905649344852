import styled from "styled-components";
import { Popover } from "antd";

const StyledPopover = styled(Popover)`
    .ant-popover-arrow {
        display: none !important;
    }
    .ant-popover-inner-content {
        width: 180px !important;
        background: rgba(215, 250, 243, 1) !important;
    }
`;

export default StyledPopover;
