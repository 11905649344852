const accountsActionTypes = {
    GET_ACCOUNT_DETAILS: 'GET_ACCOUNT_DETAILS',
    GET_ACCOUNT_DETAILS_PENDING: 'GET_ACCOUNT_DETAILS_PENDING',
    GET_ACCOUNT_DETAILS_FULFILLED: 'GET_ACCOUNT_DETAILS_FULFILLED',
    GET_ACCOUNT_DETAILS_REJECTED: 'GET_ACCOUNT_DETAILS_REJECTED',

    EDIT_ACCOUNT_DETAILS: 'EDIT_ACCOUNT_DETAILS',
    EDIT_ACCOUNT_DETAILS_PENDING: 'EDIT_ACCOUNT_DETAILS_PENDING',
    EDIT_ACCOUNT_DETAILS_FULFILLED: 'EDIT_ACCOUNT_DETAILS_FULFILLED',
    EDIT_ACCOUNT_DETAILS_REJECTED: 'EDIT_ACCOUNT_DETAILS_REJECTED',

    GET_PACKAGES: 'GET_PACKAGES',
    GET_PACKAGES_PENDING: 'GET_PACKAGES_PENDING',
    GET_PACKAGES_FULFILLED: 'GET_PACKAGES_FULFILLED',
    GET_PACKAGES_REJECTED: 'GET_PACKAGES_REJECTED',

    BUY_PACKAGE: 'BUY_PACKAGE',
    BUY_PACKAGE_PENDING: 'BUY_PACKAGE_PENDING',
    BUY_PACKAGE_FULFILLED: 'BUY_PACKAGE_FULFILLED',
    BUY_PACKAGE_REJECTED: 'BUY_PACKAGE_REJECTED',
};

export default accountsActionTypes;
