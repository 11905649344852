import React from "react";
import { useHistory } from "react-router-dom";
import map from "lodash/map";
import styled from "styled-components";

import goalieIcon from "shared/assets/icons/goalie@2x.png";
import refreshIcon from "shared/assets/icons/refresh.png";
import { Tabs, TabPane } from "shared/components/Tabs";
import { Horizontal, Vertical } from "shared/components/Wrapper";
const TabWrapper = styled.div`
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Table = styled.table`
  width: 100%;
  margin: 0;
`;
const TableHead = styled.thead``;
const TableBody = styled.tbody``;
const TableHeader = styled.th`
  color: #ffffff;
  font-family: Druk;
  font-size: 12px;
  text-decoration: underline;
`;
const TableRow = styled.tr`
  border: ${props =>
    props.highlighted ? "1px solid rgba(15, 230, 189, 1)" : "none"};
`;
const TableData = styled.td`
  color: rgba(215, 250, 243, 1);
  font-family: ProximaNova;
  font-size: 13px;
  font-weight: bold;
`;
const TableID = styled(TableData)`
  color: rgba(15, 230, 189, 1);
`;
const ScoreHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;
const ScoreIcon = styled.img`
  margin-right: 20px;
`;
const ScoreTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
`;
const ScoreTitleHeader = styled.h2`
  font-family: ProximaNova;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  margin: 0;
`;
const ScoreTitleContent = styled.h2`
  font-family: Druk;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
`;
const ScoreContent = styled.div``;
const ScoreTablePoints = styled.td`
  color: ${props =>
    props.value > 0 ? "rgba(15, 230, 189, 1)" : "rgba(205, 15, 230, 1)"};
  font-family: ProximaNova;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
`;
const ScoreTableTitle = styled(TableData)`
  text-align: left;
`;
const RefreshIcon = styled.img`
  height: 40px;
  width: auto;
`;
const RankingTitle = styled.h2`
  font-family: ProximaNova;
  font-size: 9px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
`;
const GoToContestsButton = styled.button`
  font-size: ProximaNova;
  font-weight: bold;
  font-size: 10px;
  color: rgba(9, 14, 46, 1);
  background: rgba(15, 230, 189, 1);
  border: 1px solid rgba(15, 230, 189, 1);
  outline: none;
  padding: 5px 10px;
`;
const EnterAgainButton = styled.button`
font-size: ProximaNova;
  font-weight: bold;
  font-size: 10px;
  color: rgba(215, 250, 243, 1);
  border: 1px solid rgba(215, 250, 243, 1);
  outline: none;
  background: transparent;
  padding: 5px 10px;
`;

function ContestResultsTabs({ rankings, scores, score, rank, id }) {
  const history = useHistory();
  const renderRankings = () => {
    if (rankings) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader></TableHeader>
              <TableHeader>UserName</TableHeader>
              <TableHeader>Points</TableHeader>
              <TableHeader>Prize</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(rankings, (row, index) => {
              return (
                <TableRow key={index}>
                  <TableID>{`#${row.rank || "N"}`}</TableID>
                  <TableData>{row.user_id}</TableData>
                  <TableData>{row.score}</TableData>
                  <TableData>{row.prize}</TableData>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
    return (
      <Vertical padding="20px">
        <RefreshIcon src={refreshIcon} />
        <RankingTitle>
          Ranking table is available upon completion of the contest
        </RankingTitle>
        <Horizontal margin="10px 0 0" justifyContent="space-between" width="100%">
          <GoToContestsButton
            onClick={() => {
              history.push("/app/contests");
            }}
          >
            GO TO CONTESTS
          </GoToContestsButton>
          <EnterAgainButton
            onClick={() => {
              history.push(`/app/contest/${id}`);
            }}
          >
            ENTER AGAIN
          </EnterAgainButton>
        </Horizontal>
      </Vertical>
    );
  };

  const renderScores = () => {
    return map(scores, (row, index) => {
      return (
        <TableRow key={index}>
          <ScoreTablePoints value={row.point}>
            {row.point > 0 ? `+${row.point}` : row.point}
          </ScoreTablePoints>
          <ScoreTableTitle>{row.reason}</ScoreTableTitle>
        </TableRow>
      );
    });
  };

  return (
    <Tabs defaultActiveKey="1" onChange={() => {}}>
      <TabPane tab="YOUR SCORE" key="1">
        <TabWrapper>
          <ScoreHeader>
            <ScoreIcon src={goalieIcon} />
            <ScoreTitleWrapper>
              <ScoreTitleHeader>TOTAL</ScoreTitleHeader>
              <ScoreTitleContent>{score}</ScoreTitleContent>
            </ScoreTitleWrapper>
            <ScoreTitleWrapper>
              <ScoreTitleHeader>RANK</ScoreTitleHeader>
              <ScoreTitleContent>{rank}</ScoreTitleContent>
            </ScoreTitleWrapper>
          </ScoreHeader>
          <ScoreContent>
            <Table>
              <TableBody>{renderScores()}</TableBody>
            </Table>
          </ScoreContent>
        </TabWrapper>
      </TabPane>
      <TabPane tab="CONTEST RANK" key="2">
        <TabWrapper>{renderRankings()}</TabWrapper>
      </TabPane>
    </Tabs>
  );
}

export default ContestResultsTabs;
