import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Spinner from "shared/components/Spinner";
import { AppContainer } from "pages/commonStyles";
import CacheBuster from "./CacheBuster";
import { store, persistor } from "./store";
import Routes from "./Routes";

const App = () => {
  return (
    <AppContainer>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return <Spinner />;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
          return (
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <Router>
                  <Switch>
                    <Route path="/" component={Routes} />
                  </Switch>
                </Router>
              </PersistGate>
            </Provider>
          );
        }}
      </CacheBuster>
    </AppContainer>
  );
};

export default App;
