import preferencesActionTypes from './preferencesActionTypes';

const getSports = (payload) => {
    return {
        type: preferencesActionTypes.GET_SPORTS,
        payload,
    };
};

const getSportsInfo = (payload) => {
    return {
        type: preferencesActionTypes.GET_SPORTS_INFO,
        payload,
    };
};

const addPreferences = (payload) => {
    return {
        type: preferencesActionTypes.ADD_PREFERENCES,
        payload,
    };
};

export {
    getSports,
    getSportsInfo,
    addPreferences
};
