import contestsActionTypes from "./contestsActionTypes";

const getContests = payload => {
  return {
    type: contestsActionTypes.GET_CONTESTS,
    payload
  };
};

const getContest = payload => {
  return {
    type: contestsActionTypes.GET_CONTEST,
    payload
  };
};

const joinContest = payload => {
  return {
    type: contestsActionTypes.JOIN_CONTEST,
    payload
  };
};

const getContestResult = payload => {
  return {
    type: contestsActionTypes.GET_CONTEST_RESULT,
    payload
  };
};

export { getContests, getContest, joinContest, getContestResult };
