import React from "react";
import styled from "styled-components";

import replayDark from "shared/assets/icons/replay_dark.png";
import nextIcon from "shared/assets/icons/next.png";

export const AppContainer = styled.div`
  height: 100vh;
`;

export const AppContent = styled.div`
  height: calc(100vh - 110px);
  margin: 0 auto;
  overflow-x: scroll;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1020px;
`;

export const Footer = styled.div`
  height: 50px;
  max-width: 1020px;
  margin: 0 auto;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  width: ${props => (props.block ? "100%" : "200px")};
  background: ${props => props.disabled ? 'rgba(175, 175, 175, 1)' : 'rgba(15, 230, 189, 1)'};
  border: ${props => props.disabled ? '1px solid rgba(175, 175, 175, 1)' : '1px solid rgba(15, 230, 189, 1)'};
  color: #ffffff;
  padding: 10px 25px;
  height: ${props => props.height || "auto"};
`;
export const ButtonText = styled.h2`
  margin: 0;
  color: rgba(9, 14, 46, 1);
  font-family: ProximaNova;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
`;
export const ButtonIcon = styled.img`
  height: 16px;
  width: auto;
`;
export const ContestPointsContainer = styled.div`
  position: relative;
  width: 25px;
  height: auto;
  margin-right: 5px;
`;
export const ContestPointsIcon = styled.img`
  position: relative;
  height: 25px;
  width: auto;
`;
export const ContestPoints = styled.span`
  color: rgba(9, 14, 46, 1);
  position: absolute;
  top: 8px;
  left: 6px;
  font-size: 10px;
  font-family: ProximaNova;
  font-weight: bold;
  z-index: 2;
`;
const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const NextButton = ({ disabled, onClick }) => {
  return (
    <Button block disabled={disabled} height="50px" onClick={onClick}>
       <TextContainer>
       <ButtonText>NEXT</ButtonText>
       </TextContainer>
      <ButtonIcon src={nextIcon} />
    </Button>
  );
};

export const BigEnterContestButton = ({ disabled, entryPoints, onClick }) => {
  return (
    <Button block disabled={disabled} height="50px" onClick={onClick}>
      <TextContainer>
      <ContestPointsContainer>
          <ContestPointsIcon src={replayDark} />
          <ContestPoints>{entryPoints}</ContestPoints>
      </ContestPointsContainer>
      <ButtonText>ENTER CONTEST</ButtonText>
      </TextContainer>
      <ButtonIcon src={nextIcon} />
    </Button>
  );
};


