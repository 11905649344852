import React, { useState } from "react";
import map from "lodash/map";
import uniq from "lodash/uniq";
import forEach from "lodash/forEach";
import filter from "lodash/filter";

import filterIcon from "shared/assets/icons/filter.png";
import Modal from 'shared/components/Modal';
import {
  UpdateItemsContainer,
  UpdateContainer,
  UpdateTitle,
  NextButton,
  ChangeSection,
  ActionsContainer,
  FilterIcon,
  SearchInput,
  SearchSelect,
  SearchOption
} from "./styles";
import Item from "./Item";

const UpdateTeams = ({
  visible,
  setVisibility,
  selectedSports,
  alreadySelectedTeams,
  sportsInfo,
  addPreferences,
  refreshAccountDetails,
  onChange
}) => {
  const [selectedTeams, setSelectedTeams] = useState(alreadySelectedTeams);
  const [name, setName] = useState("");
  const [country, setCountry] = useState(null);

  const renderTeamsSection = () => {
    const countries = uniq(map(sportsInfo.data.teams, team => team.country));
    const handleSearchByNameChange = e => {
      setName(e.target.value);
    };
    const handleSearchByCountryChange = value => {
      setCountry(value);
    };

    const handleNext = () => {
      const onSuccess = () => {
        setVisibility(false);
        refreshAccountDetails();
      };
      const prefs = {};
      const teams = filter(sportsInfo.data.teams, team =>
        selectedTeams.includes(team.id)
      );
      prefs["type"] = "TEAM";
      prefs["select"] = {};
      forEach(uniq(map(teams, team => team.sport_id)), sport => {
        prefs["select"][sport] = map(
          filter(teams, team => team.sport_id === sport),
          team => team.id
        );
      });

      addPreferences({
        data: {
          prefs
        },
        onSuccess
      });
    };

    const renderTeams = teams => {
      const removeTeam = teamId => {
        setSelectedTeams(
          filter(selectedTeams, selectedTeam => selectedTeam !== teamId)
        );
      };

      return map(teams, team => {
        return (
          <Item
            key={team.id}
            item={team}
            type="Club"
            onSelect={id => setSelectedTeams([...selectedTeams, id])}
            onRemove={id => removeTeam(id)}
            isSelected={selectedTeams.includes(team.id)}
          />
        );
      });
    };

    const renderSelectedTeams = () => {
      let selected = filter(
        sportsInfo.data.teams,
        team =>
          selectedSports.includes(team.sport_id) &&
          alreadySelectedTeams.includes(team.id)
      );
      if (country) {
        selected = filter(selected, team => team.country === country);
      }
      if (name) {
        selected = filter(selected, team =>
          team.name.toLowerCase().includes(name.toLowerCase())
        );
      }
      return renderTeams(selected);
    };
    const renderUnSelectedTeams = () => {
      let unSelected = filter(
        sportsInfo.data.teams,
        team =>
          selectedSports.includes(team.sport_id) &&
          !alreadySelectedTeams.includes(team.id)
      );
      if (country) {
        unSelected = filter(unSelected, team => team.country === country);
      }
      if (name) {
        unSelected = filter(unSelected, team =>
          team.name.toLowerCase().includes(name.toLowerCase())
        );
      }
      return renderTeams(unSelected);
    };

    return (
      <UpdateContainer>
        <ChangeSection onClick={onChange}>SELECT LEAGUE</ChangeSection>
        <UpdateTitle>YOUR CLUB</UpdateTitle>
        <ActionsContainer>
          <FilterIcon src={filterIcon} />
          <SearchInput
            placeholder="Search by name"
            onChange={handleSearchByNameChange}
          />
          <SearchSelect
            placeholder="Search by country"
            onChange={handleSearchByCountryChange}
            size="small"
          >
            {map(countries, (country, index) => (
              <SearchOption key={index} value={country}>
                {country}
              </SearchOption>
            ))}
          </SearchSelect>
        </ActionsContainer>
        <UpdateItemsContainer>
          {renderSelectedTeams()}
          {renderUnSelectedTeams()}
        </UpdateItemsContainer>
        <NextButton block onClick={handleNext}>
          NEXT
        </NextButton>
      </UpdateContainer>
    );
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      onOk={() => {}}
      onCancel={() => {
        setVisibility(false);
      }}
    >
      {renderTeamsSection()}
    </Modal>
  );
};

export default UpdateTeams;
