import React, { Component } from "react";
import styled from "styled-components";
import { Player, ControlBar, BigPlayButton } from "video-react";
import map from "lodash/map";
import min from "lodash/min";

import Slider from "shared/components/Slider";

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const VideoOverlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const PlayerRepresentation = styled.div`
  position: absolute;
  z-index: 2;
  background: ${props => props.color};
  width: ${props => (props.selected ? "20px" : "10px")};
  height: ${props => (props.selected ? "20px" : "10px")};
  border-radius: 100%;
  top: ${props => `${props.top}%`};
  left: ${props => `${props.left}%`};
`;

const PitchImage = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.7;
`;

export default class VideoPlayer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      slider: null,
      videoLoaded: false,
      sliderMarks: {},
    };
  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  onPlayerLoad = () => {
    const sliderMarks = this.getSliderMarks();
    // this.props.setSelectedPosition(sliderMarks[parseInt(min(Object.keys(sliderMarks)))]);
    this.setState({
      slider: min(Object.keys(sliderMarks)),
      sliderMarks,
      videoLoaded: true,
    });
  };

  handleStateChange(state) {
    this.setState({
      player: state
    });
  }

  handleChange = value => {
    // this.props.setSelectedPosition(this.state.sliderMarks[value]);
      this.setState({
        slider: value
      });
      this.player.seek(this.state.sliderMarks[value]);
      this.player.pause();
  };

  getSliderMarks = () => {
    return Object.assign({}, ...map(Object.keys(this.props.playerPositions.positions), (position) => ({
      [Math.round(parseInt(position)*100/this.state.player.duration)]: parseInt(position)
    })));
  }

  renderPlayerPositions = () => {
    const { players, playerPositions, selectedPlayer } = this.props;
    const { slider, sliderMarks } = this.state;
    return map(playerPositions.positions[sliderMarks[slider]], (position, index) => {
      return (
        <PlayerRepresentation
          key={index}
          selected={selectedPlayer === position.player_id}
          color={players[position.player_id].color}
          top={position.y}
          left={position.x}
        />
      );
    });
  };

  getDuration = () => {
    const pad = (num) => {
      return ("0"+num).slice(-2);
    }
    const duration = Math.round(this.state.player.duration);
    if (duration >= 3600) {
      const hours = Math.floor(duration/3600);
      const minutes = Math.floor((duration%3600)/60);
      const seconds = duration - (hours*3600) - (minutes *60);
      return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }
    const minutes = Math.floor(duration/60);
    const seconds = duration - (minutes *60);
    return `${pad(minutes)}:${pad(seconds)}`;
  }

  render() {
    return ( 
      <React.Fragment>
        <PlayerWrapper>
          <VideoOverlay>
            <PitchImage src={this.props.feed.pitch_overlay} alt="" />
            {this.renderPlayerPositions()}
          </VideoOverlay>
          <Player
            ref={player => {
              this.player = player;
            }}
            fluid={true}
            startTime={0}
            width="100%"
            onLoadedData={this.onPlayerLoad}
          >
            <source src={this.props.feed.video_url} />
            <BigPlayButton className="videoButton" position="center" />
            <ControlBar disableCompletely={true} />
          </Player>
        </PlayerWrapper>
        {this.state.videoLoaded ? (
        <div style={{position: 'relative'}}>
          <span style={{color: '#ffffff', position: 'absolute', top: '-25px', zIndex: '3', left: `calc(${this.state.slider}% - 10px)`}}>{`${this.state.sliderMarks[this.state.slider]} s`}</span>
          <span style={{color: '#ffffff', position: 'absolute', top: '-15px', right: '10px', zIndex: '3'}}>{this.getDuration()}</span>
        <Slider
          value={this.state.slider}
          tooltipVisible={false}
          marks={this.state.sliderMarks}
          step={null}
          onChange={this.handleChange}
        />
        </div>
        ) : null }
      </React.Fragment>
    );
  }
}
