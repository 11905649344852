const contestsActionTypes = {
  GET_CONTESTS: "GET_CONTESTS",
  GET_CONTESTS_PENDING: "GET_CONTESTS_PENDING",
  GET_CONTESTS_FULFILLED: "GET_CONTESTS_FULFILLED",
  GET_CONTESTS_REJECTED: "GET_CONTESTS_REJECTED",
  
  GET_CONTEST: "GET_CONTEST",
  GET_CONTEST_PENDING: "GET_CONTEST_PENDING",
  GET_CONTEST_FULFILLED: "GET_CONTEST_FULFILLED",
  GET_CONTEST_REJECTED: "GET_CONTEST_REJECTED",
  
  JOIN_CONTEST: "JOIN_CONTEST",
  JOIN_CONTEST_PENDING: "JOIN_CONTEST_PENDING",
  JOIN_CONTEST_FULFILLED: "JOIN_CONTEST_FULFILLED",
  JOIN_CONTEST_REJECTED: "JOIN_CONTEST_REJECTED",

  GET_CONTEST_RESULT: "GET_CONTEST_RESULT",
  GET_CONTEST_RESULT_PENDING: "GET_CONTEST_RESULT_PENDING",
  GET_CONTEST_RESULT_FULFILLED: "GET_CONTEST_RESULT_FULFILLED",
  GET_CONTEST_RESULT_REJECTED: "GET_CONTEST_RESULT_REJECTED",
};

export default contestsActionTypes;
