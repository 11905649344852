import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getSports, getSportsInfo, addPreferences } from 'features/preferences/preferencesActions';
import { getHeaders } from 'shared/apiHelper';

import NewPage from './NewPage';

const mapStateToProps = state => ({
    sports: state.preferencesReducer.sports,
    sportsInfo: state.preferencesReducer.sportsInfo,
    token: state.authReducer.token,
});

const mapDispatchToProps = dispatch => ({
    getSports: params => dispatch(getSports(params)),
    getSportsInfo: params => dispatch(getSportsInfo(params)),
    addPreferences: params => dispatch(addPreferences(params)),
});


class NewPageContainer extends React.PureComponent {
    static propTypes = {
        getSports: PropTypes.func.isRequired,
        getSportsInfo: PropTypes.func.isRequired,
        addPreferences: PropTypes.func.isRequired,
        sports: PropTypes.object.isRequired,
        sportsInfo: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        token: PropTypes.object.isRequired,
    }

    componentDidMount() {
        this.getSports();
    }

    getSports = () => {
        const { token, getSports } = this.props;
        const headers = getHeaders(token);
        getSports({
            headers,
        });
    }

    getSportsInfo = (params) => {
        const { token, getSportsInfo } = this.props;
        const headers = getHeaders(token);
        getSportsInfo({
            headers,
            params
        });
    }

    addPreferences = (params) => {
        const { token, addPreferences } = this.props;
        const headers = getHeaders(token);
        addPreferences({
            ...params,
            headers,
        });
    }

    render() {
        if (this.props.sports.isFulfilled) {
            return (
                <NewPage
                    getSportsInfo={this.getSportsInfo}
                    sports={this.props.sports.data}
                    sportsInfo={this.props.sportsInfo}
                    addPreferences={this.addPreferences}
                />
            );
        }
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPageContainer));
