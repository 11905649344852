import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import map from "lodash/map";

import Notification from 'features/notifications/Notification';
import { getContests } from "features/contests/contestsActions";
import { getHeaders } from "shared/apiHelper";
import { AppContainer } from "pages/commonStyles";
import Header from "features/header";
import NavigationMenu from "pages/NavigationMenu";
import Contest from "./Contest";
import Spinner from 'shared/components/Spinner';
import { Tabs, TabPane } from "shared/components/Tabs";
import { PageContainer, ContestsContainer } from "./styles";
import Button from 'shared/components/Button';
import emptyStateIcon from 'shared/assets/icons/empty_state.png';

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;
`;
const EmptyStateIcon = styled.img`
  height: 290px;
  width: auto;
`;
const EmptyStateDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
`;
const EmptyStateTitle = styled.h2`
  font-size: 14px;
  font-family: Druk;
  color: #ffffff;
  margin-bottom: 10px;
`;
const EmptyStateText = styled.span`
  font-size: 14px;
  font-family: ProximaNova;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
`;
const EmptyStateButton = styled(Button)`
  width: 200px;
`;

function ContestsPage() {
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const dispatch = useDispatch();
  const { contests } = useSelector(({ contestsReducer }) => contestsReducer);
  const { token } = useSelector(({ authReducer }) => authReducer);
  const headers = getHeaders(token);
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    if (!hasFetchedData && !contests.data) {
      setHasFetchedData(true);
      dispatch(
        getContests({
          headers
        })
      );
    }
  }, [hasFetchedData, contests, dispatch, headers]);

  const refreshData = () => {
    dispatch(
      getContests({
        headers
      })
    );
  }

  const renderContests = (contests, type) => {
    if (contests && contests.length > 0) {
      return map(contests, (contest, index) => {
        return <Contest key={index} contest={contest} type={type} refreshData={refreshData} />;
      });
    }
    return (
      <EmptyStateWrapper>
        <EmptyStateIcon src={emptyStateIcon} />
        <EmptyStateDetailsWrapper>
          <EmptyStateTitle>NO CONTESTS YET</EmptyStateTitle>
          <EmptyStateText>Explore the contests section & take part in contests to WIN! Your activity & videos will show up here.</EmptyStateText>
          <EmptyStateButton onClick={() => {setActiveTab("1")}}>FIND CONTEST</EmptyStateButton>
        </EmptyStateDetailsWrapper>
      </EmptyStateWrapper>
    );
  };

  return (
    <AppContainer>
      <Header leftSection={<NavigationMenu />} title="CONTESTS" rightSection={<Notification />} />
      <PageContainer>
        {contests.data ? (
          <Tabs activeKey={activeTab} onChange={(activeKey) => {setActiveTab(activeKey)}}>
            <TabPane tab="ALL CONTESTS" key="1">
              <ContestsContainer>{renderContests(contests.data.all, "all")}</ContestsContainer>
            </TabPane>
            <TabPane tab="YOUR CONTESTS" key="2">
              <ContestsContainer>{renderContests(contests.data.me, "joined")}</ContestsContainer>
            </TabPane>
          </Tabs>
        ) : <Spinner />}
      </PageContainer>
    </AppContainer>
  );
}

export default ContestsPage;
