const feedsActionTypes = {
  GET_FEEDS: "GET_FEEDS",
  GET_FEEDS_PENDING: "GET_FEEDS_PENDING",
  GET_FEEDS_FULFILLED: "GET_FEEDS_FULFILLED",
  GET_FEEDS_REJECTED: "GET_FEEDS_REJECTED",

  GET_VIDEOS: "GET_VIDEOS",
  GET_VIDEOS_PENDING: "GET_VIDEOS_PENDING",
  GET_VIDEOS_FULFILLED: "GET_VIDEOS_FULFILLED",
  GET_VIDEOS_REJECTED: "GET_VIDEOS_REJECTED",

  GET_PLAYER_POSITIONS: "GET_PLAYER_POSITIONS",
  GET_PLAYER_POSITIONS_PENDING: "GET_PLAYER_POSITIONS_PENDING",
  GET_PLAYER_POSITIONS_FULFILLED: "GET_PLAYER_POSITIONS_FULFILLED",
  GET_PLAYER_POSITIONS_REJECTED: "GET_PLAYER_POSITIONS_REJECTED",

  REPLACE_PLAYER: "REPLACE_PLAYER",
  REPLACE_PLAYER_PENDING: "REPLACE_PLAYER_PENDING",
  REPLACE_PLAYER_FULFILLED: "REPLACE_PLAYER_FULFILLED",
  REPLACE_PLAYER_REJECTED: "REPLACE_PLAYER_REJECTED",

  RECREATE_VIDEO: "RECREATE_VIDEO",
  RECREATE_VIDEO_PENDING: "RECREATE_VIDEO_PENDING",
  RECREATE_VIDEO_FULFILLED: "RECREATE_VIDEO_FULFILLED",
  RECREATE_VIDEO_REJECTED: "RECREATE_VIDEO_REJECTED"
};

export default feedsActionTypes;
