import React from "react";
import PropTypes from "prop-types";
import { Hub } from "aws-amplify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getCurrentAuthenticatedUser } from "features/auth/authActions";
import Spinner from "shared/components/Spinner";
import { AppContent } from "pages/commonStyles";

const propTypes = {
  getCurrentAuthenticatedUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

class Social extends React.Component {
  componentDidMount() {
    const { history, getCurrentAuthenticatedUser } = this.props;
    const onSuccess = () => {
      history.push("/app/contests");
    };
    Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
          getCurrentAuthenticatedUser({
            onSuccess
          });
          break;
        default:
          history.push("/signin");
          break;
      }
    });
  }

  render() {
    return (
      <AppContent>
        <Spinner />
      </AppContent>
    );
  }
}

Social.propTypes = propTypes;

const mapStateToProps = state => ({
  signinData: state.authReducer.signinData
});

const mapDispatchToProps = dispatch => ({
  getCurrentAuthenticatedUser: params =>
    dispatch(getCurrentAuthenticatedUser(params))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Social));
