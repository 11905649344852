import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import map from 'lodash/map';
import filter from 'lodash/filter';
import styled from 'styled-components';
import { Badge } from 'antd';

import { getHeaders } from "shared/apiHelper";
import { updateNotifications, getNotifications } from "features/notifications/notificationsActions";
import Popover from 'shared/components/Popover';
import icon from 'shared/assets/icons/notifications.png';

const NotificationsPopoverWrapper = styled.div`
    width: 100%;
    padding: 10px;
    background: rgba(215, 250, 243, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const NotificationsPopoverHeader = styled.div`
    font-size: 12px;
    font-family: ProximaNova;
    font-weight: bold;
    color: rgba(9, 14, 46, 1);
`;
const NotificationsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const NotificationWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid rgba(132, 132, 132, 1);
`;
const NotificationImageWrapper = styled.div`
    position: relative;
`;
const NotificationImage = styled.img`
    position: relative;
    height: 30px;
    width: auto;
`;
const NotificationIcon = styled.img`
    position: absolute;
    height: 10px;
    width: auto;
    top: 25px;
    left: 80%;
`;
const NotificationTitle = styled.h2`
    font-size: 10px;
    font-family: ProximaNova;
    color: rgba(9, 14, 46, 1);
    width: 100px;
`;
const NotificationTime = styled.h2`
    font-size: 8px;
    font-family: ProximaNova;
    color: rgba(132, 132, 132, 1);
    width: 30px;
`;

const Notification = () => {
    const { notifications } = useSelector(({ notificationsReducer }) => notificationsReducer);
    const { token } = useSelector(({ authReducer }) => authReducer);
    const headers = getHeaders(token);
    const dispatch = useDispatch();
    const unReadNotifications = map(filter(notifications.data, notification => !notification.is_read), notification => notification.id);
    const notificationContent = () => {
        const renderNotifications = () => {
            return Array.isArray(notifications.data) && map(notifications.data, (notification, index) => {
                return (
                    <NotificationWrapper key={index}>
                        <NotificationImageWrapper>
                            <NotificationImage src={notification.image} alt="notification_image" />
                            <NotificationIcon src={notification.icon} alt="notification_icon" />
                        </NotificationImageWrapper>
                        <NotificationTitle>{notification.text}</NotificationTitle>
                        <NotificationTime>{
                            moment
                            .unix(notification.timestamp_create)
                            .fromNow()
                            .toUpperCase()
                        }</NotificationTime>
                    </NotificationWrapper>
                );
            })
        }

        return (
            <NotificationsPopoverWrapper>
                <NotificationsPopoverHeader>Notifications</NotificationsPopoverHeader>
                <NotificationsWrapper>
                    {renderNotifications()}
                </NotificationsWrapper>
            </NotificationsPopoverWrapper>
        );
    }

    const handleRead = () => {
        const onSuccess = () => {
            dispatch(
                getNotifications({
                  headers
                })
              );
        };
        if (unReadNotifications.length) {
            dispatch(updateNotifications({
                headers,
                data: {
                    "notification_ids": unReadNotifications
                },
                onSuccess
            }))
        }
    }

    const renderIcon = () => {
        if (unReadNotifications.length) {
            return (
                <Badge dot={true} offset={[0, 16]} color="rgba(205, 15, 230, 1)">
                    <img
                        alt=""
                        src={icon}
                        style={{ color: "#0ce6bd", height: "16px" }}
                        onClick={handleRead}
                    />
                </Badge>
            );
        }
        return (
                <img
                    alt=""
                    src={icon}
                    style={{ color: "#0ce6bd", height: "16px" }}
                    onClick={handleRead}
                />
        );
    }

    console.log(notifications);

    return (
        <Popover placement="bottomRight" content={notificationContent()} trigger="click">
           {renderIcon()}
        </Popover>
    );
}

export default Notification;
