import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledSpin = styled(Spin)``;

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#0ce6bd' }} spin />;

const Spinner = () => {
  return (
    <SpinnerWrapper>
      <StyledSpin indicator={antIcon} />
    </SpinnerWrapper>
  );
};

export default Spinner;
