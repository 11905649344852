import { call, put, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

import apiPaths from "shared/apiPaths";
import preferencesActionTypes from "./preferencesActionTypes";
import authActionTypes from "features/auth/authActionTypes";
import { TOKEN_EXPIRED_MESSAGE } from "shared/appConstants";

export function* getSports(action) {
    const {
        payload: { headers, onSuccess, onError }
    } = action;
    const apiConfig = {
        method: "GET",
        url: apiPaths.SPORTS,
        headers: headers
    };

    yield put({ type: preferencesActionTypes.GET_SPORTS_PENDING });
    try {
        const response = yield call(axios, apiConfig);
        yield put({
            type: preferencesActionTypes.GET_SPORTS_FULFILLED,
            payload: response.data.data
        });
        yield put({
            type: authActionTypes.UPDATE_TOKEN,
            payload: response.data.access_token
        });
        onSuccess && onSuccess(response.data.data);
    } catch (e) {
        const statusCode = e && e.response && e.response.status;
        const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
        if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
            yield put({ type: authActionTypes.SIGNOUT });
        }
        yield put({
            type: preferencesActionTypes.GET_SPORTS_REJECTED,
            payload: errorMessage
        });
        onError && onError(errorMessage);
    }
}

export function* getSportsSaga() {
    yield takeLatest(preferencesActionTypes.GET_SPORTS, getSports);
}

export function* getSportsInfo(action) {
    const {
        payload: { headers, params, onSuccess, onError }
    } = action;
    const apiConfig = {
        method: "GET",
        url: apiPaths.SPORTS_INFO,
        headers: headers,
        params: params
    };

    yield put({ type: preferencesActionTypes.GET_SPORTS_INFO_PENDING });
    try {
        const response = yield call(axios, apiConfig);
        yield put({
            type: preferencesActionTypes.GET_SPORTS_INFO_FULFILLED,
            payload: response.data.data
        });
        yield put({
            type: authActionTypes.UPDATE_TOKEN,
            payload: response.data.access_token
        });
        onSuccess && onSuccess(response.data.data);
    } catch (e) {
        const statusCode = e && e.response && e.response.status;
        const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
        if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
            yield put({ type: authActionTypes.SIGNOUT });
        }
        yield put({
            type: preferencesActionTypes.GET_SPORTS_INFO_REJECTED,
            payload: errorMessage
        });
        onError && onError(errorMessage);
    }
}

export function* getSportsInfoSaga() {
    yield takeLatest(preferencesActionTypes.GET_SPORTS_INFO, getSportsInfo);
}

export function* addPreferences(action) {
    const {
        payload: { headers, data, onSuccess, onError }
    } = action;
    const apiConfig = {
        method: "POST",
        url: apiPaths.ADD_PREFERENCES,
        headers: headers,
        data: data,
    };

    yield put({ type: preferencesActionTypes.ADD_PREFERENCES_PENDING });
    try {
        const response = yield call(axios, apiConfig);
        yield put({
            type: preferencesActionTypes.ADD_PREFERENCES_FULFILLED,
            payload: response.data.data
        });
        yield put({
            type: authActionTypes.UPDATE_TOKEN,
            payload: response.data.access_token
        });
        onSuccess && onSuccess(response.data.data);
    } catch (e) {
        const statusCode = e && e.response && e.response.status;
        const errorMessage =
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message;
        if (statusCode === 401 && errorMessage === TOKEN_EXPIRED_MESSAGE) {
            yield put({ type: authActionTypes.SIGNOUT });
        }
        yield put({
            type: preferencesActionTypes.ADD_PREFERENCES_REJECTED,
            payload: errorMessage
        });
        onError && onError(errorMessage);
    }
}

export function* addPreferencesSaga() {
    yield takeLatest(preferencesActionTypes.ADD_PREFERENCES, addPreferences);
}

export default function* rootSaga() {
    yield all([fork(getSportsSaga), fork(getSportsInfoSaga), fork(addPreferencesSaga)]);
}
