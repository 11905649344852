import React, { useState, useEffect } from "react";
import {
  Switch,
  Redirect,
  Route,
  useLocation,
  useHistory
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getHeaders } from "shared/apiHelper";
import { getAccountDetails } from "features/accounts/accountsActions";
import { getNotifications } from "features/notifications/notificationsActions";
import SignIn from "pages/auth/signin";
import SignUp from "pages/auth/signup";
import Social from "pages/auth/social";
import MainApp from "./MainApp";

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  preferences,
  ...rest
}) => {
  const renderRoute = props => {
    if (authUser.data && authUser.data.idToken) {
      if (authUser.data.idToken.payload["custom:preferences"] || location.pathname === "/app/new" || preferences.data === "User info is updated.") {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: "/app/new",
              state: { from: location }
            }}
          />
        );
      }
    }
    return (
      <Redirect
        to={{
          pathname: "/signin",
          state: { from: location }
        }}
      />
    );
  };
  return <Route {...rest} render={renderRoute} />;
};

const Routes = () => {
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [hasFetchedNotifications, setHasFetchedNotifications] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { signinData, token } = useSelector(({ authReducer }) => authReducer);
  const { notifications } = useSelector(({ notificationsReducer }) => notificationsReducer);
  const { account } = useSelector(({ accountsReducer }) => accountsReducer);
  const { preferences } = useSelector(({ preferencesReducer }) => preferencesReducer);

  useEffect(() => {
    if (signinData.data && signinData.data.idToken && !hasFetchedData && !account.data) {
      const headers = getHeaders(token);
      setHasFetchedData(true);
      dispatch(
        getAccountDetails({
          headers
        })
      );
    }
    if (signinData.data && signinData.data.idToken && !notifications.data && !hasFetchedNotifications) {
      const headers = getHeaders(token);
      setHasFetchedNotifications(true);
      dispatch(
        getNotifications({
          headers
        })
      );
    }
    if (location.pathname === "/") {
      history.push("/app/contests");
    }
  }, [
    hasFetchedData,
    hasFetchedNotifications,
    notifications,
    account,
    token,
    dispatch,
    signinData,
    location,
    history
  ]);

  return (
    <Switch>
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/social" component={Social} />
      <RestrictedRoute
        path="/app"
        component={MainApp}
        location={location}
        preferences={preferences}
        authUser={signinData}
      />
    </Switch>
  );
};

export default Routes;
