import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";
import map from "lodash/map";
import filter from "lodash/filter";
import concat from "lodash/concat";

import Notification from 'features/notifications/Notification';
import Video from "./Video";
import Header from "features/header";
import NavigationMenu from "pages/NavigationMenu";
import Button from 'shared/components/Button';
import { AppContainer, AppContent } from "pages/commonStyles";
import emptyStateIcon from 'shared/assets/icons/empty_state.png';

const PageContainer = styled(AppContent)`
  padding: 20px 0;
  height: calc(100vh - 60px);
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  align-items: center;
`;
const EmptyStateIcon = styled.img`
  height: 290px;
  width: auto;
`;
const EmptyStateDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
`;
const EmptyStateTitle = styled.h2`
  font-size: 14px;
  font-family: Druk;
  color: #ffffff;
  margin-bottom: 10px;
`;
const EmptyStateText = styled.span`
  font-size: 14px;
  font-family: ProximaNova;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
`;
const EmptyStateButton = styled(Button)`
  width: 200px;
`;

const VideosPage = ({ videos }) => {
  const [activeVideos, setActiveVideos] = useState([]);
  const history = useHistory();

  const onChangeVisibility = (index, isVisible) => {
    if (isVisible && !activeVideos.includes(index)) {
      setActiveVideos(concat(activeVideos, index));
    }
    if (!isVisible && activeVideos.includes(index)) {
      setActiveVideos(filter(activeVideos, i => i !== index));
    }
  };

  const renderVideos = () => {
    if (videos.length > 0) {
      return map(videos, (video, index) => {
        return (
          <VisibilitySensor
            key={index}
            onChange={isVisible => onChangeVisibility(index, isVisible)}
          >
            <Video
              videoIndex={index}
              video={video}
              visible={activeVideos[0] === index}
            />
          </VisibilitySensor>
        );
      });
    }
    return (
      <EmptyStateWrapper>
        <EmptyStateIcon src={emptyStateIcon} />
        <EmptyStateDetailsWrapper>
          <EmptyStateTitle>NO VIDEOS YET</EmptyStateTitle>
          <EmptyStateText>Explore the videos to recreate history</EmptyStateText>
          <EmptyStateButton onClick={() => history.push("/app/feeds")}>EXPLORE VIDEOS</EmptyStateButton>
        </EmptyStateDetailsWrapper>
      </EmptyStateWrapper>
    );
  };

  return (
    <AppContainer>
      <Header leftSection={<NavigationMenu />} title="MY REPLAYS" rightSection={<Notification />} />
      <PageContainer>{renderVideos()}</PageContainer>
    </AppContainer>
  );
};

export default withRouter(VideosPage);
