const baseUrl = process.env.REACT_APP_API_URL;

const apiPaths = {
    SPORTS: `${baseUrl}/api/sports`,
    ACCOUNT: `${baseUrl}/api/account`,
    CONTESTS: `${baseUrl}/api/contests`,
    CONTEST: `${baseUrl}/api/contest`,
    CONTEST_RESULT: `${baseUrl}/api/contestResults`,
    JOIN_CONTEST: `${baseUrl}/api/joinContest`,
    PACKAGES: `${baseUrl}/api/packages`,
    BUY_PACKAGE: `${baseUrl}/api/payPackage`,
    EDIT_ACCOUNT: `${baseUrl}/api/editAccount`,
    SPORTS_INFO: `${baseUrl}/api/sportInfo`,
    ADD_PREFERENCES: `${baseUrl}/api/addPref`,
    FEEDS: `${baseUrl}/api/videos`,
    MY_VIDEOS: `${baseUrl}/api/myVideos`,
    PLAYER_POSITIONS: `${baseUrl}/api/playerPositions`,
    REPLACE_PLAYER: `${baseUrl}/api/replacePlayers`,
    RECREATE_VIDEO: `${baseUrl}/api/startProcess`,
    GET_NOTIFICATIONS: `${baseUrl}/api/notification/list`,
    UPDATE_NOTIFICATIONS: `${baseUrl}/api/notification/update`,
};

export default apiPaths;
