import React, { Component } from "react";
import moment from "moment";
import { Player, BigPlayButton } from "video-react";

import stat1Logo from "shared/assets/icons/football-pitch-ball@2x.png";
import stat2Logo from "shared/assets/icons/goalie@2x.png";
import {
  PlayerWrapper,
  TopOverlayWrapper,
  TopOverlayLeftSection,
  TopOverlayRightSection,
  Title,
  LeagueLogo,
  EventLogo,
  EventTime,
  ActorName,
  BottomOverlayWrapper,
  BottomOverlayLeftSection,
  BottomOverlayRightSection,
  EventDate,
  EventDetailsWrapper,
  TeamLogo,
  EventDetail,
  StatsWrapper,
  StatsImage,
  StatsTitle,
  StatsNumber
} from "./styles";

export default class Feed extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  handleStateChange(state) {
    this.setState({
      player: state
    });
  }

  render() {
    const { feed } = this.props;
    return (
      <PlayerWrapper>
        <TopOverlayWrapper playerState={this.state.player}>
          <TopOverlayLeftSection>
            <LeagueLogo
              src={feed.event_details && feed.event_details.league.logo}
            />
            <Title>{feed.message}</Title>
          </TopOverlayLeftSection>
          <TopOverlayRightSection>
            <EventTime>{`${feed.moment_details.time}'`}</EventTime>
            <EventLogo src={feed.moment_details.icon} />
            <ActorName>{feed.moment_details.player.name}</ActorName>
          </TopOverlayRightSection>
        </TopOverlayWrapper>
        <BottomOverlayWrapper playerState={this.state.player}>
          <BottomOverlayLeftSection>
            <EventDate>
              {moment
                .unix(feed.event_details.date)
                .format("DD MMM YYYY")}
            </EventDate>
            <EventDetailsWrapper>
              <TeamLogo src={feed.event_details.teams.home.logo} />
              <EventDetail>{feed.event_details.result}</EventDetail>
              <TeamLogo src={feed.event_details.teams.away.logo} />
            </EventDetailsWrapper>
          </BottomOverlayLeftSection>
          <BottomOverlayRightSection>
            <StatsWrapper>
              <StatsImage src={stat1Logo} />
              <StatsTitle>REPLAYED</StatsTitle>
              <StatsNumber>{feed.stats.replayed}</StatsNumber>
            </StatsWrapper>
            <StatsWrapper>
              <StatsImage src={stat2Logo} />
              <StatsTitle>CHANGED</StatsTitle>
              <StatsNumber>{feed.stats.changed}</StatsNumber>
            </StatsWrapper>
          </BottomOverlayRightSection>
        </BottomOverlayWrapper>
        <Player
          src={this.props.feed.video_url}
          poster={this.props.feed.video_thumbnail}
          ref={player => {
            this.player = player;
          }}
        >
          <BigPlayButton className="123" position="center" />
        </Player>
      </PlayerWrapper>
    );
  }
}
