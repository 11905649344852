import React from "react";
import styled from "styled-components";
import map from "lodash/map";

import { Vertical } from "shared/components/Wrapper";
import Modal from "shared/components/Modal";

const Title = styled.h2`
  font-family: Druk;
  color: #ffffff;
  font-size: 14px;
  margin: 0 0 30px;
`;
const Table = styled.table`
  width: 100%;
`;
const TableRow = styled.tr`
  border-bottom: 1px solid rgba(215, 250, 243, 1);
  padding: 10px 0;
  height: 30px;
`;
const Rank = styled.td`
  color: rgba(15, 230, 189, 1);
  font-size: 15px;
  font-family: ProximaNova;
  font-weight: bold;
  text-align: left;
`;
const Prize = styled.td`
  color: rgba(215, 250, 243, 1);
  font-size: 15px;
  font-family: ProximaNova;
  font-weight: bold;
  text-align: right;
`;

function PrizeBreakup({ visible, setVisibility, breakup }) {
  return (
    <Modal
      visible={visible}
      footer={null}
      onOk={() => {}}
      onCancel={() => {
        setVisibility(false);
      }}
    >
      <Vertical padding="30px">
        <Title>PRIZE TABLE</Title>
        <Table>
          {map(breakup, (row, index) => {
            return (
              <TableRow key={index}>
                <Rank>
                  {row.ranks.length === 1
                    ? `#${row.ranks[0]}`
                    : `#${row.ranks[0]}-${row.ranks[1]}`}
                </Rank>
                <Prize>{row.amount}</Prize>
              </TableRow>
            );
          })}
        </Table>
      </Vertical>
    </Modal>
  );
}

export default PrizeBreakup;
