import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { notification } from "antd";
import Header from "features/header";

import { signup } from "features/auth/authActions";
import Button from "shared/components/Button";
import { Input } from "shared/components/Input";
import { Form, FormItem } from "shared/components/Form";
import Checkbox from "shared/components/Checkbox";
import snapifyIcon from "shared/assets/icons/Snapify_Logo@2x.png";
import facebookIcon from "shared/assets/icons/facebook_circle.png";
import googleIcon from "shared/assets/icons/google_circle.png";
import { AppContent, Footer } from "pages/commonStyles";

const CustomAppContent = styled(AppContent)`
  padding: 35px 15px;
`;
const StyledButton = styled(Button)`
  height: 100%;
`;
const SocialButtonsContainer = styled.div``;
const SocialButton = styled.img`
  height: 50px;
  width: 50px;
  margin-right: 20px;
`;
const OR = styled.h2`
  font-family: ProximaNova;
  font-weight: bold;
  font-size: 20px;
  margin: 17px auto;
  color: rgba(15, 230, 189);
`;
const SignupForm = styled(Form)`
  margin: 17px 0 0;
`;
const SignupFormItem = styled(FormItem)`
  margin: 0 30px 20px;
`;
const LoginButton = styled(Button)`
  height: 33px;
`;
const StyledInput = styled(Input)`
  margin-bottom: 20px;
  background: rgba(215, 250, 243, 1);
`;

const SignUp = ({ history }) => {
  const dispatch = useDispatch();
  const { signupData } = useSelector(({ authReducer }) => authReducer);
  const validatePassword = (rule, value, callback) => {
    if (value && !value.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$")) {
      callback(
        "Password should be of 8 characters and have atleast one uppercase letter and one lowercase letter and one digit"
      );
    }
    callback();
  };

  const onSuccess = () => {
    notification.success({
      message:
        "Please verify your email address by clicking on the verification link sent to your email address"
    });
    history.push("/signin");
  };

  const onError = error => {
    notification.error({
      message: error
    });
  };

  const handleSubmit = values => {
    dispatch(
      signup({
        data: {
          name: values.name,
          username: values.email,
          password: values.password,
          attributes: {
            email: values.email,
            name: values.name,
          }
        },
        onSuccess,
        onError
      })
    );
  };

  return (
    <React.Fragment>
      <Header
        leftSection={<img src={snapifyIcon} alt="" width="30px" />}
        title="SIGN UP"
      />
      <CustomAppContent>
        <SocialButtonsContainer>
          <SocialButton
            src={facebookIcon}
            onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
          />
          <SocialButton
            src={googleIcon}
            onClick={() => Auth.federatedSignIn({ provider: "Google" })}
          />
        </SocialButtonsContainer>
        <OR>OR</OR>
        <SignupForm onFinish={handleSubmit} onFinishFailed={() => {}}>
          <SignupFormItem
            name="name"
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <StyledInput type="text" placeholder="Full Name" />
          </SignupFormItem>
          <SignupFormItem
            name="email"
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <StyledInput type="text" placeholder="Email" />
          </SignupFormItem>
          <SignupFormItem
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!"
              },
              {
                validator: validatePassword
              }
            ]}
          >
            <StyledInput type="password" placeholder="Password" />
          </SignupFormItem>
          <SignupFormItem
            name="confirm"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!"
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                }
              })
            ]}
          >
            <StyledInput type="password" placeholder="Confirm Password" />
          </SignupFormItem>
          <SignupFormItem
            name="checkbox1"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject("Please accept Privacy Policy")
              }
            ]}
          >
            <Checkbox>
              By creating an account you agree to our Terms of Service and
              Privacy Policy
            </Checkbox>
          </SignupFormItem>
          <SignupFormItem
            name="checkbox2"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject("Please accept Privacy Policy")
              }
            ]}
          >
            <Checkbox>
              You agree to share your personal data with selected third parties,
              for example, your club and it's sponsors
            </Checkbox>
          </SignupFormItem>
          <LoginButton loading={signupData.isPending} block htmlType="submit">
            SIGN UP
          </LoginButton>
        </SignupForm>
      </CustomAppContent>
      <Footer>
        <StyledButton
          background="rgba(215, 250, 243, 1)"
          block
          onClick={() => {
            history.push("/signin");
          }}
        >
          Already have an account?
        </StyledButton>
      </Footer>
    </React.Fragment>
  );
};

export default withRouter(SignUp);
