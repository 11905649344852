import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import map from 'lodash/map';

import Notification from 'features/notifications/Notification';
import { getFeeds } from "features/feeds/feedsActions";
import { getHeaders } from "shared/apiHelper";
import { AppContainer } from "pages/commonStyles";
import Header from "features/header";
import NavigationMenu from "pages/NavigationMenu";
import Spinner from 'shared/components/Spinner';
import Feed from "./Feed";
import {
  PageContainer,
  FeedsContainer,
  FeedCard,
  RecreateButton
} from "./styles";

function FeedsPage() {
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { feeds } = useSelector(({ feedsReducer }) => feedsReducer);
  const { token } = useSelector(({ authReducer }) => authReducer);
  const headers = getHeaders(token);

  useEffect(() => {
    if (!feeds.data && !hasFetchedData) {
      setHasFetchedData(true);
      dispatch(
        getFeeds({
          headers
        })
      );
    }
  }, [feeds, hasFetchedData, headers, dispatch]);

  const onClickRecreate = feed => {
    history.push({
      pathname: `/app/recreate`,
      state: { feed }
    });
  };
  const renderFeeds = () => {
    return map(feeds.data, (feed, index) => {
      return (
        <FeedCard key={index}>
          <Feed feed={feed} />
          <RecreateButton
            type="primary"
            block
            onClick={() => onClickRecreate(feed)}
          >
            RE-CREATE HISTORY
          </RecreateButton>
        </FeedCard>
      );
    });
  };

  return (
    <AppContainer>
      <Header leftSection={<NavigationMenu />} title="EXPLORE" rightSection={<Notification />} />
      <PageContainer>
        {feeds.data ? <FeedsContainer>{renderFeeds()}</FeedsContainer> : <Spinner />}
      </PageContainer>
    </AppContainer>
  );
}

export default FeedsPage;
