import React from "react";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  display: flex;
  background: rgba(9, 14, 46, 1);
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  padding: 15px;
  height: 60px;
  align-items: center;
`;
const LeftSection = styled.div`
  width: 40px;
`;
const Title = styled.h2`
  font-family: ProximaNova;
  color: white;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  text-align: center;
`;
const RightSection = styled.div`
  width: 40px;
`;

const Header = ({ title, leftSection, rightSection }) => {
  return (
    <HeaderWrapper>
      <Wrapper>
        <LeftSection>{leftSection}</LeftSection>
        <Title>{title}</Title>
        <RightSection>
          {rightSection}
        </RightSection>
      </Wrapper>
    </HeaderWrapper>
  );
};

export default Header;
