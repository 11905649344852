import React, { useState } from "react";
import map from "lodash/map";
import filter from "lodash/filter";

import Header from "features/header";
import snapifyIcon from "shared/assets/icons/Snapify_Logo@2x.png";

import { AppContainer, AppContent, Footer, NextButton } from "pages/commonStyles";
import {
  Card,
  ItemTitle,
  ItemImage,
  ItemButton,
  TitleSection,
  MainTitle,
  SubTitle,
  ItemList
} from "./styles";
import LeaguesAndClubs from "./LeaguesAndClubs";

const NewPage = ({ sports, addPreferences, getSportsInfo, sportsInfo }) => {
  const [selectedSports, setSelectedSports] = useState([]);
  const [currentTab, setCurrentTab] = useState("s");

  const generateSportsInfo = () => {
    setCurrentTab("leagues");
    getSportsInfo({
      ids: selectedSports.join(",")
    });
  };

  const renderSportsSection = () => {
    const handleSportsClick = id => {
      if (selectedSports.includes(id)) {
        setSelectedSports(filter(selectedSports, sportId => sportId !== id));
      } else {
        setSelectedSports([...selectedSports, id]);
      }
    };
    const renderSports = () => {
      return map(sports, (sport, index) => {
        return (
          <Card key={index}>
            <ItemTitle>{sport.name}</ItemTitle>
            <ItemImage src={sport.icon} alt="" />
            <ItemButton
              type={
                selectedSports.includes(sport.sport_id)
                  ? "secondary"
                  : "primary"
              }
              onClick={() => handleSportsClick(sport.sport_id)}
            >
              {selectedSports.includes(sport.sport_id) ? "FOLLOWING" : "FOLLOW"}
            </ItemButton>
          </Card>
        );
      });
    };
    return (
      <React.Fragment>
        <AppContent>
          <TitleSection>
            <MainTitle>SPORTS</MainTitle>
            <SubTitle>Pick sports that you follow</SubTitle>
          </TitleSection>
          <ItemList>{renderSports()}</ItemList>
        </AppContent>
        <Footer>
          <NextButton disabled={selectedSports.length === 0} onClick={generateSportsInfo} />
        </Footer>
      </React.Fragment>
    );
  };

  if (currentTab === "s") {
    return (
      <AppContainer>
        <Header
          leftSection={<img src={snapifyIcon} alt="" width="30px" />}
          title="CREATE ACCOUNT"
        />
        {renderSportsSection()}
      </AppContainer>
    );
  } else {
    return (
      <LeaguesAndClubs
        setCurrentTab={setCurrentTab}
        sportsInfo={sportsInfo}
        addPreferences={addPreferences}
      />
    );
  }
};

export default NewPage;
