import React, { useState } from "react";
import map from "lodash/map";
import flatMap from "lodash/flatMap";
import filter from "lodash/filter";
import find from "lodash/find";

import Notification from 'features/notifications/Notification';
import Header from "features/header";
import NavigationMenu from "pages/NavigationMenu";
import { AppContainer } from "pages/commonStyles";
import profileIcon from "shared/assets/icons/profile.png";
import editIcon from "shared/assets/icons/edit.png";
import UpdateSports from "./UpdateSports";
import UpdateTeams from "./UpdateTeams";
import UpdateLeagues from "./UpdateLeagues";
import UpdateName from "./UpdateName";
import WalletContainer from "./WalletContainer";

import {
  PageContainer,
  UserContainer,
  UserDetailsContainer,
  ProfileIcon,
  UserName,
  EditIcon,
  PreferencesContainer,
  PreferencesHeaderContainer,
  PreferencesHeader,
  ItemDetails,
  ItemIcon,
  ItemTitleContainer,
  ItemTitle,
  ItemSubTitle,
  ItemsContainer,
  ItemContainer
} from "./styles";

const AccountPage = ({
  account,
  buyPackage,
  packages,
  sports,
  sportsInfo,
  addPreferences,
  refreshAccountDetails,
  editAccountDetails
}) => {
  const [selectedSports, setSelectedSports] = useState(
    Object.keys(account.data.preferences.select)
  );
  const [selectedTeams, setSelectedTeams] = useState(
    account.data.preferences.type === "TEAM"
      ? flatMap(account.data.preferences.select)
      : []
  );
  const [selectedLeagues, setSelectedLeagues] = useState(
    account.data.preferences.type === "LEAGUE"
      ? flatMap(account.data.preferences.select)
      : []
  );
  const [updateSportsVisible, setUpdateSportsVisibile] = useState(false);
  const [updateLeaguesVisible, setUpdateLeaguesVisibile] = useState(false);
  const [updateTeamsVisible, setUpdateTeamsVisibile] = useState(false);
  const [updateNameVisible, setUpdateNameVisibile] = useState(false);

  const onUpdateSports = () => {
    setSelectedTeams(
      map(
        filter(
          sportsInfo.data.teams,
          team =>
            selectedTeams.includes(team.id) &&
            selectedSports.includes(team.sport_id)
        ),
        team => team.id
      )
    );
    setSelectedLeagues(
      map(
        filter(
          sportsInfo.data.leagues,
          league =>
            selectedLeagues.includes(league.id) &&
            selectedSports.includes(league.sport_id)
        ),
        league => league.id
      )
    );
    setUpdateSportsVisibile(false);
    setUpdateTeamsVisibile(true);
  };

  const onChangeLeagueToTeam = () => {
    setUpdateLeaguesVisibile(false);
    setUpdateTeamsVisibile(true);
  };
  const onChangeTeamToLeague = () => {
    setUpdateTeamsVisibile(false);
    setUpdateLeaguesVisibile(true);
  };

  const renderSportsChild = sportId => {
    const childType =
      account.data.preferences && account.data.preferences.type === "LEAGUE"
        ? "leagues"
        : "teams";
    return map(
      filter(sportsInfo.data[childType], child =>
        account.data.preferences.select[sportId].includes(child.id)
      ),
      (child, index) => {
        return (
          <ItemContainer key={index}>
            <ItemDetails>
              <ItemIcon src={child.icon} />
              <ItemTitleContainer>
                <ItemTitle>{child.name}</ItemTitle>
                <ItemSubTitle>Favourite {childType}</ItemSubTitle>
              </ItemTitleContainer>
            </ItemDetails>
          </ItemContainer>
        );
      }
    );
  };

  const renderSports = () => {
    return map(
      Object.keys(account.data.preferences.select),
      (sportId, index) => {
        const sportDetails = find(sports, sport => sport.sport_id === sportId);
        return (
          <ItemContainer key={index}>
            <ItemDetails>
              <ItemIcon src={sportDetails.icon} />
              <ItemTitleContainer>
                <ItemTitle>{sportDetails.name}</ItemTitle>
                <ItemSubTitle>Favourite Sport</ItemSubTitle>
              </ItemTitleContainer>
            </ItemDetails>
            <ItemsContainer>{renderSportsChild(sportId)}</ItemsContainer>
          </ItemContainer>
        );
      }
    );
  };

  return (
    <AppContainer>
      <Header leftSection={<NavigationMenu />} title="ACCOUNT" rightSection={<Notification />} />
      <PageContainer>
        <UserContainer>
          <UserDetailsContainer>
            <ProfileIcon src={profileIcon} />
            <UserName>{account.data.name}</UserName>
          </UserDetailsContainer>
          <EditIcon
            onClick={() => setUpdateNameVisibile(true)}
            src={editIcon}
          />
        </UserContainer>
        <WalletContainer
          account={account}
          packages={packages}
          buyPackage={buyPackage}
          refreshAccountDetails={refreshAccountDetails}
        />
        <PreferencesContainer>
          <PreferencesHeaderContainer>
            <PreferencesHeader>PREFERENCES</PreferencesHeader>
            <EditIcon
              onClick={() => setUpdateSportsVisibile(true)}
              src={editIcon}
            />
          </PreferencesHeaderContainer>
          {renderSports()}
        </PreferencesContainer>
      </PageContainer>
      <UpdateSports
        visible={updateSportsVisible}
        setVisibility={setUpdateSportsVisibile}
        selectedSports={selectedSports}
        setSelectedSports={setSelectedSports}
        sports={sports}
        onUpdateSports={onUpdateSports}
      />
      <UpdateTeams
        visible={updateTeamsVisible}
        setVisibility={setUpdateTeamsVisibile}
        selectedSports={selectedSports}
        alreadySelectedTeams={selectedTeams}
        // selectedTeams={selectedTeams}
        // setSelectedTeams={setSelectedTeams}
        sportsInfo={sportsInfo}
        addPreferences={addPreferences}
        refreshAccountDetails={refreshAccountDetails}
        onChange={onChangeTeamToLeague}
      />
      <UpdateLeagues
        visible={updateLeaguesVisible}
        setVisibility={setUpdateLeaguesVisibile}
        selectedSports={selectedSports}
        alreadySelectedLeagues={selectedLeagues}
        sportsInfo={sportsInfo}
        addPreferences={addPreferences}
        refreshAccountDetails={refreshAccountDetails}
        onChange={onChangeLeagueToTeam}
      />
      <UpdateName
        account={account}
        editAccountDetails={editAccountDetails}
        visible={updateNameVisible}
        setVisibility={setUpdateNameVisibile}
        refreshAccountDetails={refreshAccountDetails}
      />
    </AppContainer>
  );
};

export default AccountPage;
