import preferencesActionTypes from "./preferencesActionTypes";

const initialState = {
  sports: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  sportsInfo: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  },
  preferences: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null
  }
};

const preferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case preferencesActionTypes.GET_SPORTS_PENDING:
      return Object.assign({}, state, {
        sports: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case preferencesActionTypes.GET_SPORTS_FULFILLED:
      return Object.assign({}, state, {
        sports: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case preferencesActionTypes.GET_SPORTS_REJECTED:
      return Object.assign({}, state, {
        sports: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case preferencesActionTypes.GET_SPORTS_INFO_PENDING:
      return Object.assign({}, state, {
        sportsInfo: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case preferencesActionTypes.GET_SPORTS_INFO_FULFILLED:
      return Object.assign({}, state, {
        sportsInfo: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case preferencesActionTypes.GET_SPORTS_INFO_REJECTED:
      return Object.assign({}, state, {
        sportsInfo: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    case preferencesActionTypes.ADD_PREFERENCES_PENDING:
      return Object.assign({}, state, {
        preferences: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null
        }
      });
    case preferencesActionTypes.ADD_PREFERENCES_FULFILLED:
      return Object.assign({}, state, {
        preferences: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload
        }
      });
    case preferencesActionTypes.ADD_PREFERENCES_REJECTED:
      return Object.assign({}, state, {
        preferences: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload
        }
      });
    default:
      return state;
  }
};

export default preferencesReducer;
