import React from "react";
import moment from "moment";
import styled from "styled-components";

const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
const DetailsRightSection = styled.div`
  display: flex;
  align-items: center;
`;
const EventLogo = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;
const EventTime = styled.h2`
  color: white;
  border: 1px solid white;
  border-radius: 100%;
  font-size: 12px;
  padding: 2px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const ActorName = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;

const DetailsLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const EventDate = styled.h2`
  color: white;
  font-size: 12px;
  font-family: ProximaNova;
  font-weight: bold;
  margin: 0;
`;
const EventDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TeamLogo = styled.img`
  width: 20px;
  height: 20px;
`;
const EventDetail = styled.h2`
  color: white;
  font-size: 12px;
  font-family: Druk;
  margin: 0 10px;
`;

const MomentDetailsDisplayCard = ({ feed }) => {
  return (
    <DetailsWrapper>
      <DetailsLeftSection>
        <EventDate>
          {moment
            .unix(feed.event_details.date)
            .format("DD MMM YYYY")}
        </EventDate>
        <EventDetailsWrapper>
          <TeamLogo src={feed.event_details.teams.home.logo} />
          <EventDetail>{feed.event_details.result}</EventDetail>
          <TeamLogo src={feed.event_details.teams.away.logo} />
        </EventDetailsWrapper>
      </DetailsLeftSection>
      <DetailsRightSection>
        <EventTime>{`${feed.moment_details.time}'`}</EventTime>
        <EventLogo src={feed.moment_details.icon} />
        <ActorName>{feed.moment_details.player.name}</ActorName>
      </DetailsRightSection>
    </DetailsWrapper>
  );
};

export default MomentDetailsDisplayCard;
